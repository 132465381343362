import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardBody, Form, Row, CardHeader, Input, Label } from 'reactstrap';
import { Btn } from '../../../../AbstractElements';
import { useForm } from 'react-hook-form';
import { FInput, FSelect, FCheckbox, FUserSelect, FDateTime } from 'CommonElements/Form';
import { clientService, activitiesService } from 'Services';
import SweetAlert from 'sweetalert2';
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';
import { toast } from 'react-toastify';
import countriesStates from '../../../Common/json/countriesStates.json';
import { idTypeCodes, sourceOfFundCodes, modeOfPaymentCodes } from '../../../Common/json/amlShortCode.js';


const BasicInformation = (props) => {
    const {
        customerId = '',
        data = {},
    } = props;

    const [submitState, setSubmitState] = useState({
        loading: false,
    })
    const [chkTrade, setChkTrade] = useState(false);

    const permissions = useSelector(
        (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
    );
    const { register, handleSubmit, formState: { errors }, setValue, getValues, control } = useForm();
    useEffect(() => {
        const formFields = getValues();
        Object.keys(formFields).forEach(key => {
            if (data[key]) {
                setValue(key, data[key])
            }
        })
    }, []);

    const [tradeEnabled, setTradeEnabled] = useState(data.tradeDisabled);
    const [tradeDisabled, setTradeDisabled] = useState(!data.tradeDisabled);
    const [dbTradeDisable, setDbTradeDisable] = useState(data.tradeDisabled);

    const [screeningPass, setScreeningPass] = useState(data.screenFailed);
    const [screeningFailed, setScreeningFailed] = useState(!data.screenFailed);
    const [dbScreeningFailed, setDbScreeningFailed] = useState(data.screenFailed);

    const [compliancePass, setCompliancePass] = useState(data.complianceFailed);
    const [complianceFailed, setComplianceFailed] = useState(!data.complianceFailed);
    const [dbComplianceFailed, setDbComplianceFailed] = useState(data.complianceFailed);

    const handleTradeEnableChange = () => {
        if (compliancePass === true && screeningPass === true) {
            setTradeEnabled(true);
            setChkTrade(true);
            setTradeDisabled(false); // Set tradeDisabled to opposite of tradeEnabled
            setDbTradeDisable(true);
        } else {
            setTradeEnabled(false);
            setTradeDisabled(true); // Set tradeDisabled to opposite of tradeEnabled
            setDbTradeDisable(false);
            toast.error('Please enable screening and complaince!');
        }
    };

    const handleTradeDisableChange = () => {
        setTradeDisabled(true);
        setTradeEnabled(false); // Set tradeEnabled to opposite of tradeDisabled
        setDbTradeDisable(false);
    };

    const handleScreenPassChange = () => {
        if (compliancePass === true) {
            setScreeningPass(true);
            setScreeningFailed(false); // Set tradeDisabled to opposite of tradeEnabled
            setDbScreeningFailed(true);
        } else {
            setScreeningPass(false);
            setScreeningFailed(true); // Set tradeDisabled to opposite of tradeEnabled
            setDbScreeningFailed(false);
            toast.error('Please enable complaince!');
        }
    };

    const handleScreenFailedChange = () => {
        setScreeningFailed(true);
        setScreeningPass(false); // Set tradeEnabled to opposite of tradeDisabled
        setDbScreeningFailed(false);
    };

    const handleCompliancePassChange = () => {
        setCompliancePass(true);
        setComplianceFailed(false); // Set tradeDisabled to opposite of tradeEnabled
        setDbComplianceFailed(true);

        const newDate = new Date();
        data.complianceDate = newDate;
        newDate.setFullYear(newDate.getFullYear() + 1); // Add 1 year to complianceCompleted date
        setPredioticDate(newDate.toISOString().split('T')[0]);
    };

    const handleComplianceFailedChange = () => {
        setComplianceFailed(true);
        setCompliancePass(false); // Set tradeEnabled to opposite of tradeDisabled
        setDbComplianceFailed(false);
    };

    const [predioticDate, setPredioticDate] = useState(null);

    useEffect(() => {
        if (data.complianceCompleted) {
            const complianceDate = new Date(data.complianceCompleted);
            const newDate = new Date(complianceDate);
            newDate.setFullYear(newDate.getFullYear() + 1); // Add 1 year to complianceCompleted date
            setPredioticDate(newDate.toISOString().split('T')[0]);
        }
    }, [data.complianceCompleted]);

    const aggrements = [
        { name: 'US Citizen?', value: 'usCitizen' },
        { name: 'Politically Exposed-Person?', value: 'politicallyExposed' },
        { name: 'Accepted Terms & Conditions', value: 'acceptedTerms' },
        { name: 'Accepted Risk Disclosure?', value: 'acceptedRisk' },
        { name: 'Accepted Privacy Policy?', value: 'acceptedPrivacy' },
        { name: 'Accepted Order Execution Policy?', value: 'acceptedExecutionPolicy' },
        { name: 'Accepted Conflicts Of Interest Policy?', value: 'acceptedConflicts' },
        { name: 'Accepted electronic trading terms?', value: 'acceptTradingTerms' }
    ];

    const updateAccountStatus = (data) => {
        switch (data.approvalStage) {
            case "Documents Approved":
            case "Pending Documents":
                data.accountStatus = "Pending";
                break;
            case "KYC / CDD Approved":
                data.accountStatus = "Approved";
                data.kycStatus = "approved";
                break;
            case "Trade Enabled/Active":
                data.accountStatus = "Active";
                data.kycStatus = "approved";
                break;
            default:
                // Optionally handle cases where approvalStage doesn't match any of the above
                data.accountStatus = data.accountStatus;
                break;
        }
        return data;
    };

    const onSubmit = formData => {
        if (formData !== '') {
            setSubmitState({ loading: true });
            delete formData.createdAt;
            delete formData.updatedAt;
            delete formData.ftDepositAmount;
            delete formData.netDepositInUSD;
            delete formData.ftDepositTime;
            delete formData.depositInUSD;
            delete formData.withdrawInUSD;
            delete formData.lastDepositDate;
            delete formData.Agent;
            delete formData.Transactions;
            delete formData.complianceDate;
            delete formData.Parent;
            delete formData.Group;
            delete formData.Retention;
            chkTrade && (formData.tradeDisabled = dbTradeDisable || false);
            formData.complianceFailed = dbComplianceFailed || false;
            formData.screenFailed = dbScreeningFailed || false;
            predioticDate && (formData.predioticDate = predioticDate);
            predioticDate && (formData.complianceCompleted = new Date());

            updateAccountStatus(formData);
            console.log("formData is ", formData);

            formData.idTypeCode = idTypeCodes[formData.idType];
            formData.sourceOfFundsCode = sourceOfFundCodes[formData.sourceOfFunds];
            formData.modeOfPaymentCode = modeOfPaymentCodes[formData.modeOfPayment];
            formData.countryCode = formData.country && `${countriesStates.filter(ctry => ctry.name === formData.country)[0].iso2}`;
            formData.nationalityCode = formData.nationality && `${countriesStates.filter(ctry => ctry.name === formData.nationality)[0].iso2}`

            // Step 1: Compare form data with the original `data` object
            const changedData = {};
            Object.keys(formData).forEach(key => {
                if (formData[key] !== data[key]) {
                    changedData[key] = formData[key]; // Only capture the fields that were changed
                }
            });

            console.log('Changed data: ', changedData);

            clientService.updateClient(customerId, changedData).then(res => {
                setSubmitState({
                    loading: false,
                    status: 'Profile updated sucessfully'
                })

                const logData = {
                    type: `Basic info Updated`,
                    userId: permissions.id,
                    createdAt: Date.now(),
                    customerId: customerId,
                    note: changedData
                };

                activitiesService.postActivity(logData);

                SweetAlert.fire({ title: 'Success', text: 'Profile updated sucessfully!', icon: 'success' }).then(() => {
                    // Refresh the page after the alert is closed
                    // window.location.reload();
                });
            }).catch(err => {
                setSubmitState({
                    loading: false,
                    status: 'Error in updating Profile'
                })
                SweetAlert.fire({ title: 'Failed', text: err.response.data.message, icon: 'error' });
            });
            // alert('You submitted the form and stuff!');
        } else {
            errors.showMessages();
        }
    };

    return (
        <Fragment>
            <Card>
                <CardHeader className='p-3'>
                    Basic Information Coportate
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col md='4 mb-3' >
                                <FInput
                                    errors={errors}
                                    label='Full Corporate Name'
                                    name='firstName'
                                    register={register}
                                    placeholder='Enter First Name'
                                    validation={{ required: true }}
                                />
                            </Col>
                            <Col md='4' >
                                <FUserSelect
                                    control={control}
                                    errors={errors}
                                    label='Account Owner'
                                    name='agentId'
                                    validation={{ required: true }}
                                    setValue={setValue}
                                    value={data.agentId}
                                    defaultOptions={data.Agent ? [data.Agent] : []}
                                // isClient={true}
                                />
                            </Col>
                            {getMenuPerm(permissions.isHidden, 'entity:hide') != 1 &&
                                <Col md='4' >
                                    <FSelect
                                        control={control}
                                        errors={errors}
                                        label='Entity'
                                        name='entity'
                                        register={register}
                                        //placeholder= 'Select Client Type'
                                        options={[
                                            { name: 'GODO Financial', value: 'FSA' },
                                            { name: 'GODO', value: 'FSC' },
                                            { name: 'GODO Introduction', value: 'SCA' },
                                            { name: 'GODO Morocco', value: 'FSA_MA' },
                                            { name: 'GODO Abbado', value: 'FSA_CA' }
                                        ]}
                                        disabled={getMenuPerm(permissions, 'entity:View') === 1 && true}
                                        value={data.entity || ''}
                                        validation={{ required: false }}
                                        setValue={setValue}
                                    />
                                </Col>
                            }
                            <Col md='4 mb-3' >
                                <FInput
                                    errors={errors}
                                    label='Country of Incorporation'
                                    name='country'
                                    register={register}
                                    placeholder='Enter Country of Residence'
                                    validation={{ required: true }}
                                />
                            </Col>
                            <Col md='4 mb-3' >
                                <FInput
                                    errors={errors}
                                    label='Establishment Date'
                                    name='dob'
                                    register={register}
                                    placeholder='Enter Date of Birth'
                                    type="date"
                                />
                            </Col>
                            <Col md='4 mb-3' >
                                <FInput
                                    errors={errors}
                                    label='License Number'
                                    name='corporateLicense'
                                    // register={register}
                                    placeholder='Enter License Number'
                                    defaultValue={data?.Corporate[0]?.corporateLicense}
                                />
                            </Col>
                            <Col md='4 mb-3' >
                                <FInput
                                    errors={errors}
                                    label='Enter Address'
                                    name='address'
                                    register={register}
                                    placeholder='Enter Address'
                                    validation={{ required: true }}
                                />
                            </Col>
                            <Col md='4 mb-3' >
                                <FInput
                                    errors={errors}
                                    label='Business Email'
                                    name='email'
                                    register={register}
                                    placeholder='Enter Email Address'
                                    validation={{ required: true }}
                                />
                            </Col>
                            <Col md='4 mb-3' >
                                <FInput
                                    errors={errors}
                                    label='Phone'
                                    name='phone'
                                    register={register}
                                    placeholder='Enter Phone Number'
                                    validation={{ required: true }}
                                />
                            </Col>
                            <Col md='4 mb-3' >
                                <FInput
                                    errors={errors}
                                    label='Company Website'
                                    placeholder='Website'
                                    name='website'
                                    register={register}
                                    disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                />
                            </Col>
                            <Col md='4' >
                                <FSelect
                                    control={control}
                                    errors={errors}
                                    label='Account Stage'
                                    name='accountStage'
                                    register={register}
                                    //placeholder= 'Select Account Stage'
                                    options={[
                                        { name: 'Inactive', value: 'Inactive' },
                                        { name: 'Funded', value: 'Funded' },
                                        { name: 'Traded', value: 'Traded' },
                                        { name: 'Dormant', value: 'Dormant' },
                                        { name: 'Frozen', value: 'Frozen' },
                                        { name: 'Closed', value: 'Closed' },
                                        { name: 'Blacklisted', value: 'Blacklisted' },
                                    ]}

                                    validation={{ required: false }}
                                    value={
                                        data.accountStage ? data.accountStage :
                                            data.startTrading ? 'Traded' :
                                                data.isFunded ? 'Funded' :
                                                    'Inactive'
                                    }
                                    setValue={setValue}
                                    disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                />
                            </Col>
                            <Col md='4' >
                                <FUserSelect
                                    control={control}
                                    errors={errors}
                                    label='Retention Owner'
                                    name='retention'
                                    validation={{ required: false }}
                                    setValue={setValue}
                                    value={data.retention || ''}
                                    defaultOptions={data.Retention ? [data.Retention] : []}

                                // isClient={true}
                                />
                            </Col>
                            <Col md='4' style={{ display: 'flex', alignItems: 'center', marginBottom: '0' }} >
                                <FCheckbox
                                    label='Email Verified?'
                                    name='emailVerified'
                                    setValue={setValue}
                                    value={data.emailVerified || ''}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md='12 my-3 fs_20 fw_600'>
                                AUTHORISED PERSON
                            </Col>
                        </Row>
                        <hr></hr>
                        <Row>
                            <Col md='4' >
                                <FInput
                                    errors={errors}
                                    label='Name'
                                    name='fullName'
                                    // register={register}
                                    //placeholder= 'Enter Address'
                                    disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                    defaultValue={data?.AuthSignatory[0]?.fullName}
                                    validation={{ required: false }}
                                />
                            </Col>
                            <Col md='4' >
                                <FInput
                                    errors={errors}
                                    label='Job Title'
                                    name='jobTitle'
                                    // register={register}
                                    //placeholder= 'Enter City'
                                    defaultValue={data?.AuthSignatory[0]?.jobTitle}
                                    validation={{ required: false }}
                                />
                            </Col>
                            <Col md='4 mb-3' >
                                <FInput
                                    errors={errors}
                                    label='Country of Incorporation'
                                    name='country'
                                    register={register}
                                    placeholder='Enter Country of Residence'
                                    validation={{ required: true }}
                                />
                            </Col>
                            <Col md='4 mb-3' >
                                <FInput
                                    errors={errors}
                                    label='Phone'
                                    name='phone'
                                    register={register}
                                    placeholder='Enter Phone Number'
                                    validation={{ required: true }}
                                />
                            </Col>
                            <Col md='4 mb-3' >
                                <FInput
                                    errors={errors}
                                    label='Email'
                                    name='email'
                                    register={register}
                                    placeholder='Enter Email Address'
                                    defaultValue={data?.AuthSignatory[0]?.email}
                                    validation={{ required: true }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md='12 mb-3 fs_20 fw_600'>
                                Compliance  Check
                            </Col>
                        </Row>
                        <hr />
                        <Row>

                            {aggrements.map((agg, index) =>
                                // <Col md={index === aggrements.length - 1 ? '12' : '6'} mb='3' key={index}></Col>
                                <Col md='6' mb='3' key={index}>
                                    <FCheckbox
                                        label={agg.name}
                                        name={agg.value}
                                        setValue={setValue}
                                        value={data[agg.value]}
                                    />
                                </Col>
                            )}
                            <div className='mb-4'></div>
                            <Col md='6'>
                                <Label className='d-block' for={'Trade Enabled?'}>
                                    <Input
                                        checked={tradeEnabled}
                                        onChange={handleTradeEnableChange}
                                        className='checkbox_animated'
                                        name={'tradeDisabled'}
                                        id={'Trade Enabled?'}
                                        type='checkbox'
                                    />
                                    {'Trade Enabled?'}
                                </Label>
                            </Col>
                            <Col md='6'>
                                <Label className='d-block' for={'Trade Disabled?'}>
                                    <Input
                                        checked={tradeDisabled}
                                        onChange={handleTradeDisableChange}
                                        className='checkbox_animated'
                                        name={'tradeDisabled'}
                                        id={'Trade Disabled?'}
                                        type='checkbox'
                                        disabled={tradeEnabled}
                                    />
                                    {'Trade Disabled?'}
                                </Label>
                            </Col>
                            <Col md='6'>
                                <Label className='d-block' for={'Screening Pass?'}>
                                    <Input
                                        checked={screeningPass}
                                        onChange={handleScreenPassChange}
                                        className='checkbox_animated'
                                        name={'screenFailed'}
                                        id={'Screening Pass?'}
                                        type='checkbox'
                                    />
                                    {'Screening Pass?'}
                                </Label>
                            </Col>
                            <Col md='6'>
                                <Label className='d-block' for={'Screening Failed?'}>
                                    <Input
                                        checked={screeningFailed}
                                        onChange={handleScreenFailedChange}
                                        className='checkbox_animated'
                                        name={'screenFailed'}
                                        id={'Screening Failed?'}
                                        type='checkbox'
                                        disabled={screeningPass}
                                    />
                                    {'Screening Failed?'}
                                </Label>
                            </Col>
                            <Col md='6 mb-3'>
                                <Label className='d-block' for={'Compliance Pass?'}>
                                    <Input
                                        checked={compliancePass}
                                        onChange={handleCompliancePassChange}
                                        className='checkbox_animated'
                                        name={'complianceFailed'}
                                        id={'Compliance Pass?'}
                                        type='checkbox'
                                    />
                                    {'Compliance Pass?'}
                                </Label>
                            </Col>
                            <Col md='6 mb-3'>
                                <Label className='d-block' for={'Compliance Failed?'}>
                                    <Input
                                        checked={complianceFailed}
                                        onChange={handleComplianceFailedChange}
                                        className='checkbox_animated'
                                        name={'complianceFailed'}
                                        id={'Compliance Failed?'}
                                        type='checkbox'
                                        disabled={compliancePass}
                                    />
                                    {'Compliance Failed?'}
                                </Label>
                            </Col>
                            <Col md='4' >
                                <FSelect
                                    control={control}
                                    errors={errors}
                                    label='Account Status'
                                    name='accountStatus'
                                    register={register}
                                    //placeholder= 'Select Account Status'
                                    options={[
                                        { name: 'New', value: 'New' },
                                        { name: 'Pending', value: 'Pending' },
                                        { name: 'Rejected', value: 'Rejected' },
                                        { name: 'Approved', value: 'Approved' },
                                        { name: 'Active', value: 'Active' },
                                        { name: 'Frozen', value: 'Frozen' },
                                        { name: 'Closed', value: 'Closed ' },
                                        { name: 'Blacklisted', value: 'Blacklisted' },
                                        { name: 'Demo', value: 'Demo' },
                                    ]}

                                    validation={{ required: false }}
                                    value={data.accountStatus ? data.accountStatus : 'New'}
                                    //value={data.accountStatus ? data.accountStatus : ''}
                                    setValue={setValue}
                                />
                            </Col>
                            <Col md='4' >
                                <FSelect
                                    control={control}
                                    errors={errors}
                                    label='Approval Stage'
                                    name='approvalStage'
                                    register={register}
                                    placeholder={data.approvalStage}
                                    options={[
                                        { name: 'New Application', value: 'New Application' },
                                        { name: 'Documents Approved', value: 'Documents Approved' },
                                        { name: 'Pending Documents', value: 'Pending Documents' },
                                        { name: 'KYC / CDD Approved', value: 'KYC / CDD Approved' },
                                        { name: 'Trade Enabled/Active', value: 'Trade Enabled/Active' }
                                    ]}
                                    value={data.approvalStage || 'New Application'}
                                    setValue={setValue}
                                />
                            </Col>
                            <Col md='4 mb-3' style={{ display: 'none' }} >
                                <FInput
                                    errors={errors}
                                    label='Risk Level'
                                    name='riskLevel'
                                    register={register}
                                    //placeholder= 'Enter Risk Level'
                                    validation={{ required: false }}
                                    disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                />
                            </Col>
                            <Col md='4 mb-3' style={{ display: 'none' }} >
                                <FDateTime
                                    errors={errors}
                                    label='Compliance Completed'
                                    name='complianceCompleted'
                                    register={register}
                                    type="date"
                                    setValue={setValue}
                                    selectedValue={data.complianceCompleted}
                                    disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                />

                            </Col>
                            <Col md='4 mb-3' >
                                <FDateTime
                                    errors={errors}
                                    label='Periodic Check Date'
                                    name='predioticDate'
                                    register={register}
                                    type="date"
                                    selectedValue={predioticDate}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md='12 mb-3 fs_20 fw_600'>
                                Marketing info
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md='4 mb-3' >
                                <FInput
                                    errors={errors}
                                    label='UTM Campaign'
                                    //placeholder= 'UTM Campaign'
                                    name='utmCampaign'
                                    register={register}
                                    disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                />
                            </Col>
                            <Col md='4 mb-3' >
                                <FInput
                                    errors={errors}
                                    label='UTM Source'
                                    //placeholder= 'UTM Source'
                                    name='utmSource'
                                    register={register}
                                    disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                />
                            </Col>
                            <Col md='4 mb-3' >
                                <FInput
                                    errors={errors}
                                    label='UTM Medium'
                                    //placeholder= 'UTM Medium'
                                    name='utmMedium'
                                    register={register}
                                    disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                />
                            </Col>
                            <Col md='4 mb-3' >
                                <FInput
                                    errors={errors}
                                    label='UTM Term'
                                    //placeholder= 'UTM Term'
                                    name='utmTerm'
                                    register={register}
                                    disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                />
                            </Col>
                            <Col md='4 mb-3' >
                                <FInput
                                    errors={errors}
                                    label='UTM Content'
                                    //placeholder= 'UTM Content'
                                    name='utmContent'
                                    register={register}
                                    disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                />
                            </Col>
                            <Col md='4 mb-3' >
                                <FInput
                                    errors={errors}
                                    label='UTM Category'
                                    //placeholder= 'UTM Category'
                                    name='utmCategory'
                                    register={register}
                                    disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                />
                            </Col>
                        </Row>


                        {getMenuPerm(permissions, 'clients:update') === 1 &&
                            <Btn disabled={submitState.loading} attrBtn={{ color: 'primary' }} >{'Update'}</Btn>
                        }
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    );
};
export default BasicInformation;
