import React, { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Row,
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import './AdminDashboard.css';
import { UsersPersonalDetail } from 'Services/usersPersonalDetail.service';
import { VacationDays, addVacationDays } from 'Services/vacationdays.service';
import { activitiesService } from 'Services';
import { useSelector } from "react-redux";
import { UsersTimeOff, createUsersTimeOff, updateUsersTimeOff } from 'Services/usersTimeoff.service';
import { fetchAttendanceRecords, fetchCurrentMonthAttendance } from "Services/attendance.service";
import { fetchDocuments } from 'Services/managedocs.service';
import { fetchshifts } from 'Services/shifts.service';
import { ArrowLeft, Edit, Trash, PlusCircle, Eye, Upload } from "react-feather";

const HrmsAdminDashboard = () => {
  // **Loading States**
  const [loadingYetToPunchIn, setLoadingYetToPunchIn] = useState(true);
  const [loadingAbsent, setLoadingAbsent] = useState(true);
  const [loadingDocuments, setLoadingDocuments] = useState(true);
  const [loadingBirthdays, setLoadingBirthdays] = useState(true);
  const [loadingLatePunch, setLoadingLatePunch] = useState(true);
  const [loadingProbation, setLoadingProbation] = useState(true);
  const [probationEnding, setProbationEnding] = useState([]);
  const [noOfDays, setNoOfDays] = useState(1);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [timeOffType, setTimeOffType] = useState('');
  const [attendanceRecords, setAttendanceRecords] = useState([]); // Store fetched attendance data
  const [latePunches, setLatePunches] = useState([]);
  const [yetToPunchIn, setYetToPunchIn] = useState([]); // Store employees yet to punch in
  const [punchedInToday, setPunchedInToday] = useState(new Set()); // Track punched-in employees
  const [todaysAttendance, setTodaysAttendance] = useState([]); // ✅ Store today's attendance
  const [vacationModal, setVacationModal] = useState(false);
  const [vacationFormValues, setVacationFormValues] = useState([{
    empName: '',
    empNo: '',
    transactionType: '',
    fromDate: new Date().toISOString().split('T')[0], // Today
    toDate: new Date().toISOString().split('T')[0], // Today
    resumptionDate: '',
    noOfDays: 1,
    status: 'Pending',
  }]);


  const [vacations, setVacations] = useState([]);
  const [isVacationEditMode, setIsVacationEditMode] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [modal, setModal] = useState(false);
  const [formValues, setFormValues] = useState({
    eventTitle: '',
    eventPriority: 'normal',
    eventDate: new Date().toISOString().substr(0, 10),
  });

  const [today] = useState(new Date());
  const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

  const userPermissions = useSelector(
        (state) => (state.login && state.login.profile) || {}
    );


  const [upcomingBirthdays, setUpcomingBirthdays] = useState([]);
  console.log("upcom.....................", upcomingBirthdays);

  const [absent, setAbsent] = useState([]);
  console.log("Absentee", absent);

  // **Fetch Absentees**
  useEffect(() => {
    const fetchAbsentees = async () => {
      try {
        console.log("🔄 Fetching absentee data...");

        setLoadingAbsent(true);
        const absentees = await VacationDays();

        console.log("📡 API Response - Raw Data:", absentees);

        const today = new Date();
        today.setHours(0, 0, 0, 0); // ✅ Reset time to 00:00:00 to avoid mismatch

        console.log(`📅 Today's Date: ${today.toDateString()}`);

        const currentlyAbsent = absentees.filter(absentee => {
          const fromDate = new Date(absentee.fromDate);
          const toDate = new Date(absentee.toDate);

          // ✅ Reset time on fromDate and toDate to 00:00:00 for accurate comparison
          fromDate.setHours(0, 0, 0, 0);
          toDate.setHours(0, 0, 0, 0);

          console.log(`🔍 Checking: ${absentee.empName}`);
          console.log(`➡️ From: ${fromDate.toDateString()} | To: ${toDate.toDateString()} | Status: ${absentee.status}`);

          // ✅ Ensure correct comparison including single-day leaves
          const isAbsentToday = (today.getTime() >= fromDate.getTime()) && (today.getTime() <= toDate.getTime());
          const isActiveLeave = absentee.status === "Completed" || absentee.status === "In_Progress";

          console.log(`✅ Is Absent Today? ${isAbsentToday}`);
          console.log(`✅ Is Leave Status Valid? ${isActiveLeave}`);

          return isAbsentToday && isActiveLeave;
        });

        console.log("✅ Final Absentees List:", currentlyAbsent);
        setAbsent(currentlyAbsent);
      } catch (error) {
        console.error("❌ Error fetching absentees:", error);
      }
      setLoadingAbsent(false);
      console.log("⏳ Finished fetching absentees.");
    };

    fetchAbsentees();
  }, []);



  useEffect(() => {
    const fetchProbationEnding = async () => {
      try {
        setLoadingProbation(true);
        const usersData = (await UsersPersonalDetail()).filter(user => user.status === 'Active');
        const today = new Date();
        const thirtyDaysFromNow = new Date();
        thirtyDaysFromNow.setDate(today.getDate() + 30);

        const endingProbations = usersData.filter(user => {
          const probationEndDate = new Date(user.end_of_probation);
          return probationEndDate >= today && probationEndDate <= thirtyDaysFromNow;
        });

        setProbationEnding(endingProbations);
      } catch (error) {
        console.error("Error fetching probation data:", error);
      }
      setLoadingProbation(false);
    };

    fetchProbationEnding(); // ✅ Call function inside useEffect

  }, []); // ✅ Empty dependency array to run only once


  // **Fetch Upcoming Birthdays**
  useEffect(() => {
    const fetchBirthdays = async () => {
      try {
        setLoadingBirthdays(true);
        const allUsers = await UsersPersonalDetail();
        const today = new Date();
        const thirtyDaysFromNow = new Date();
        thirtyDaysFromNow.setDate(today.getDate() + 30);

        const upcoming = allUsers
          .map(user => {
            const birthDate = new Date(user.birth_date);
            let birthDateThisYear = new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate());
            if (birthDateThisYear < today) {
              birthDateThisYear.setFullYear(today.getFullYear() + 1);
            }

            const timeDiff = Math.ceil((birthDateThisYear - today) / (1000 * 60 * 60 * 24));

            return {
              ...user,
              timeDiff,
              birthDateThisYear
            };
          })
          .filter(user => user.birthDateThisYear >= today && user.birthDateThisYear <= thirtyDaysFromNow)
          .sort((a, b) => a.timeDiff - b.timeDiff);

        setUpcomingBirthdays(upcoming);
      } catch (error) {
        console.error("Error fetching upcoming birthdays:", error);
      }
      setLoadingBirthdays(false);
    };

    fetchBirthdays();
  }, []);

  const formatTimeLeft = (daysLeft) => {
    const weeks = Math.floor(daysLeft / 7);
    const days = daysLeft % 7;
    if (weeks > 0 && days > 0) {
      return `${weeks} week${weeks > 1 ? 's' : ''} and ${days} day${days > 1 ? 's' : ''} left`;
    } else if (weeks > 0) {
      return `${weeks} week${weeks > 1 ? 's' : ''} left`;
    } else {
      return `${days} day${days > 1 ? 's' : ''} left`;
    }
  };

  const toggle = () => setModal(!modal);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = () => {
    if (formValues.eventTitle) {
      setEvents([
        ...events,
        {
          date: new Date(formValues.eventDate),
          title: formValues.eventTitle,
          priority: formValues.eventPriority,
        },
      ]);
      toggle();
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const getEventColor = (priority) => {
    switch (priority) {
      case 'low':
        return 'green';
      case 'high':
        return 'red';
      default:
        return 'blue';
    }
  };
 const toggleVacationModal = (employee = null) => {
  console.log("🚀 toggleVacationModal Triggered!", employee);

  if (employee) {
    console.log("🟢 Setting Employee Data:", employee);
    const today = new Date().toISOString().split('T')[0];

    const vacationData = {
      empName: employee.name,
      empNo: employee.userid,
      transactionType: '', // Leave empty for now
      fromDate: today,
      toDate: today,
      resumptionDate: '',
      noOfDays: 1,
      status: 'Pending',
    };

    setVacationFormValues([vacationData]);
  }

  // ✅ Simply open or toggle the modal
  setVacationModal(prev => !prev);
};

  
  
const handleSaveVacation = async (e) => {
  e.preventDefault();

  const data = vacationFormValues[0];

  if (!data.transactionType) {
    toast.error("❌ Please select a transaction type.");
    return;
  }

  try {
    const userTimeOffRecords = await UsersTimeOff();

    let timeoffType = "";
    let dayAdjustment = -1;

    switch (data.transactionType.trim().toUpperCase()) {
      case "ANNUAL VACATIONS":
      case "LATE DEDUCTION":
      case "SICK LEAVE":
      case "PERSONAL LEAVE":
        timeoffType = "ANNUAL VACATIONS";
        break;
      case "SICK DAY OFF":
        timeoffType = "SICK DAY OFF";
        break;
      case "ABSENCE WITHOUT PERMISSIONS":
      case "UNPAID VACATION":
        timeoffType = "UNPAID VACATION";
        dayAdjustment = 1;
        break;
      case "MATERNITY LEAVE":
      case "PATERNITY LEAVE":
      case "PILGRIMAGE LEAVE":
        timeoffType = data.transactionType.toUpperCase();
        break;
      case "EARLY OUT":
      case "HALF DAY":
        timeoffType = "ANNUAL VACATIONS";
        dayAdjustment = -0.5;
        break;
      case "BUSINESS VACATION":
        toast.info("ℹ️ Business vacation does not affect time-off records.");
        return;
      default:
        console.warn("⚠️ Unknown transaction type:", data.transactionType);
        return;
    }
    const logData = {
                        type: `Vacation Added(Dashboard)`,
                        userId: userPermissions.id,
                        createdAt: Date.now(),
                        customerId: data.empNo,
                        note: data,
                        level: 'YELLO',

                    };
    
                    activitiesService.postActivity(logData);
    

    const userId = String(data.empNo).trim();

    // Check if this transaction type normally affects annual vacations
    const affectsAnnual = ["ANNUAL VACATIONS", "LATE DEDUCTION", "SICK LEAVE", "PERSONAL LEAVE", "EARLY OUT", "HALF DAY"]
      .includes(data.transactionType.trim().toUpperCase());

    if (affectsAnnual) {
      const carryOver = userTimeOffRecords.find(
        r => r.userid === userId && r.timeoffType.toUpperCase() === "CARRY OVER BALANCE"
      );
      const annual = userTimeOffRecords.find(
        r => r.userid === userId && r.timeoffType.toUpperCase() === "ANNUAL VACATIONS"
      );

      let deduction = Math.abs(dayAdjustment);

      if (carryOver && parseFloat(carryOver.days) > 0) {
        const carryDays = parseFloat(carryOver.days);

        if (carryDays >= deduction) {
          // Deduct fully from carry over
          await updateUsersTimeOff(carryOver.id, {
            days: (carryDays - deduction).toString()
          });
        } else {
          // Deduct part from carry over, rest from annual
          await updateUsersTimeOff(carryOver.id, { days: "0" });

          if (annual) {
            const newAnnual = parseFloat(annual.days) - (deduction - carryDays);
            await updateUsersTimeOff(annual.id, {
              days: newAnnual.toString()
            });
          } else {
            await createUsersTimeOff({
              userid: userId,
              timeoffType: "ANNUAL VACATIONS",
              days: `-${deduction - carryDays}`
            });
          }
        }
      } else {
        // No carry over available
        if (annual) {
          const newAnnual = parseFloat(annual.days) - deduction;
          await updateUsersTimeOff(annual.id, {
            days: newAnnual.toString()
          });
        } else {
          await createUsersTimeOff({
            userid: userId,
            timeoffType: "ANNUAL VACATIONS",
            days: `-${deduction}`
          });
        }
      }
    } else {
      // Normal timeoffType that doesn't affect annual balance
      const existing = userTimeOffRecords.find(
        r => r.userid === userId && r.timeoffType.toUpperCase() === timeoffType.toUpperCase()
      );

      if (existing) {
        const updatedDays = parseFloat(existing.days) + dayAdjustment;
        await updateUsersTimeOff(existing.id, { days: updatedDays.toString() });
      } else {
        await createUsersTimeOff({
          userid: userId,
          timeoffType,
          days: dayAdjustment.toString()
        });
      }
    }

    // ✅ Finally, add vacation record
    await addVacationDays(data);
    toast.success("✅ Vacation added successfully!");
    setVacationModal(false);
  } catch (error) {
    console.error("❌ Error saving vacation:", error);
    toast.error("Something went wrong! Please try again.");
  }
};


  const handleVacationFormChange = (e, id) => {
    const { name, value } = e.target;
    const updatedVacation = [...vacationFormValues];

    updatedVacation[id] = { ...updatedVacation[id], [name]: value };

    setVacationFormValues(updatedVacation);
  };


  const handleDateSelection = (date) => {
    setSelectedDate(date); // Update state with the selected date
  };

  const [expiry, setExpiry] = useState([]);
  console.log("Expiring..", expiry);

  // **Fetch Expiring Documents**
  useEffect(() => {
    const fetchExpiringDocuments = async () => {
      try {
        setLoadingDocuments(true);
        const expiringDocs = await fetchDocuments();
        const today = new Date();

        const filteredExpiry = expiringDocs.filter(document => {
          const expiryDate = new Date(document.expiryDate);
          let reminderPeriod = document.docType === "PASSPORT" ? 6 : document.docType === "VISA" ? 1 : 0;
          const reminderDate = new Date(today);
          reminderDate.setMonth(today.getMonth() + reminderPeriod);

          return expiryDate >= today && expiryDate <= reminderDate;
        });

        setExpiry(filteredExpiry);
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
      setLoadingDocuments(false);
    };

    fetchExpiringDocuments();
  }, []);

  const formatDateOnly = (date) => date.toISOString().split("T")[0];

  const loadAttendanceRecords = async () => {
    try {
      const today = new Date();
      const date = formatDateOnly(today);
      console.log("📅 Loading today's attendance:", date);
  
      const attendanceData = await fetchAttendanceRecords(`?date=${date}`);
      console.log("✅ Attendance Data Received:", attendanceData);
  
      const rows = attendanceData?.rows || [];
  
      if (!Array.isArray(rows) || rows.length === 0) {
        console.warn("⚠️ No attendance data found.");
        setAttendanceRecords([]); // optional
        return;
      }
  
      console.log("✅ Attendance Records:", rows);
  
      setAttendanceRecords(rows);
      setTodaysAttendance(rows);
    } catch (error) {
      console.error("❌ Error fetching attendance data:", error);
    }
  };
  
  
  // ✅ Call this function on component mount
  useEffect(() => {
    loadAttendanceRecords();
  }, []);


  // **Fetch Employees Yet to Punch In**
  useEffect(() => {
    if (todaysAttendance.length === 0) {
      console.log("⚠️ Skipping Yet to Punch In calculation - No attendance data yet.");
      return;
    }

    const loadYetToPunchIn = async () => {
      try {
        setLoadingYetToPunchIn(true);
        console.log("🔄 Loading employees who are yet to punch in...");
    
        // Step 1: Get all active users
        const usersData = (await UsersPersonalDetail()).filter(user => user.status === 'Active');
        console.log("✅ Active Employees:", usersData.length);
    
        // Step 2: Get today's date in YYYY-MM-DD
        const todayDate = new Date().toISOString().split("T")[0];
    
        // Step 3: Build set of employees who already punched in today
        const punchedInTodaySet = new Set(
          todaysAttendance.map(record => record.emp_code?.toString().trim())
        );
        console.log("✅ Punched In Today:", punchedInTodaySet);
    
        // Step 4: Get list of absentees for today
        const absentees = await VacationDays();
        const absenteesTodaySet = new Set(
          absentees
            .filter(record => {
              if (!record.fromDate || !record.toDate) return false;
        
              const fromDate = new Date(record.fromDate);
              const toDate = new Date(record.toDate);
        
              // Check if both dates are valid
              if (isNaN(fromDate) || isNaN(toDate)) {
                console.warn("⚠️ Invalid date in vacation record:", record);
                return false;
              }
        
              const from = fromDate.toISOString().split("T")[0];
              const to = toDate.toISOString().split("T")[0];
        
              return todayDate >= from && todayDate <= to;
            })
            .map(record => record.empNo?.toString().trim())
        );
        
        // Step 5: Map absentee user IDs (empNo) to their emp_code (`section`)
        const absenteeEmpCodes = new Set(
          usersData
            .filter(user => absenteesTodaySet.has(user.userid?.toString().trim()))
            .map(user => user.section?.toString().trim())
        );
    
        // Step 6: Find employees who:
        // - haven't punched in today
        // - aren't on vacation
        const notPunchedIn = usersData.filter(user => {
          const empCode = user.section?.toString().trim();
          return (
            empCode &&
            !punchedInTodaySet.has(empCode) &&
            !absenteeEmpCodes.has(empCode)
          );
        });
    
        console.log("✅ Employees Yet to Punch In:", notPunchedIn.length);
        setYetToPunchIn(notPunchedIn);
      } catch (error) {
        console.error("❌ Error in loadYetToPunchIn:", error);
      } finally {
        setLoadingYetToPunchIn(false);
      }
    };
    

    loadYetToPunchIn();
  }, [todaysAttendance]);


  // ✅ Fetch Late Punch-Ins (Runs After Attendance is Fetched)
  useEffect(() => {
    const fetchLatePunchIns = async () => {
      try {
        console.log("🔄 Fetching Late Punch-Ins...");

        setLoadingLatePunch(true);


        const [shiftData, usersData] = await Promise.all([
          fetchshifts(),
          UsersPersonalDetail(),
        ]);
        
        const attendanceData = attendanceRecords; // ✅ already in state
        
        

        const todayDate = new Date().toISOString().split("T")[0];

        const shiftMap = new Map(
          shiftData.rows.map(shift => [shift.emp_code, shift.hours])
        );

        console.log("✅ Shift Map Created:", shiftMap);

        const todaysAttendance = attendanceData; // No need to filter again


        const firstPunchInMap = new Map();

        todaysAttendance.forEach(record => {
          const empCode = record.emp_code;
          const punchTimeString = record.punch_time.split(".")[0];
          const punchTime = new Date(punchTimeString.replace(" ", "T"));

          if (!firstPunchInMap.has(empCode) || punchTime < firstPunchInMap.get(empCode)) {
            firstPunchInMap.set(empCode, punchTime);
          }
        });

        console.log("✅ First Punch-In Records:", Array.from(firstPunchInMap.entries()));

        const latePunchList = [];

        firstPunchInMap.forEach((punchTime, empCode) => {
          let shiftHours = shiftMap.get(empCode);
          let shiftStart;

          if (!shiftHours) {
            console.warn(`⚠️ No shift assigned to ${empCode}, defaulting to 10:00 AM`);
            shiftStart = new Date(`${todayDate}T10:00:00`);
          } else {
            const match = shiftHours.match(/(\d+):(\d+)(AM|PM)/);
            if (!match) return;

            let [_, startHour, startMinute, period] = match;
            startHour = parseInt(startHour, 10);
            if (period === "PM" && startHour !== 12) startHour += 12;
            if (period === "AM" && startHour === 12) startHour = 0;

            shiftStart = new Date(`${todayDate}T${startHour}:${startMinute}:00`);
          }

          const lateThreshold = new Date(shiftStart);
          lateThreshold.setMinutes(lateThreshold.getMinutes() + 15);

          if (punchTime.getTime() > lateThreshold.getTime()) {
            const lateMinutes = Math.round((punchTime - shiftStart) / (1000 * 60));
            const employee = usersData.find(user => user.section === empCode);
            const empName = employee ? employee.name : `Unknown (${empCode})`;

            console.log(`🚨 LATE: ${empName} punched in at ${punchTime.toLocaleTimeString()} (Late by ${lateMinutes} min)`);

            latePunchList.push({
              empCode,
              name: empName,
              punchTime: punchTime.toLocaleTimeString(),
              expectedTime: shiftStart.toLocaleTimeString(),
              lateBy: `${lateMinutes} minutes`,
            });
          }
        });

        setLatePunches(latePunchList);
        console.log("✅ Late Punch List:", latePunchList);
      } catch (error) {
        console.error("❌ Error fetching Late Punch-Ins:", error);
      }
      setLoadingLatePunch(false);
    };

    fetchLatePunchIns();
  }, [attendanceRecords]);

  // Runs only when attendance records update
  return (
    <Fragment>
      <style>
              {`
    .form-group.w-75 {
      display: none;
 
    }
      .form-inline.search-fulls.col-7 {
        border: none;
      }

    .MuiBox-root.css-19midj6 {
            padding: 3px;
      }
  `}
            </style>
  <div className="admin-dashboard container-fluid py-3">
    {/* Row 1: Who’s Off / Yet to Punch / Docs Expiring */}
    <Row className="mb-4 g-4">
      <Col md="4">
        <Card className="dashboard-card card-yellow">
          <CardHeader><h5>Who’s Off / Working Remotely 🏡</h5></CardHeader>
          <CardBody>
              {loadingAbsent ? (
                <Spinner color="primary" />
              ) : absent.length > 0 ? (
                <ul>
                  {absent.map((absentee, index) => (
                    <li key={index} className="who-is-off-item">
                      <span className="initial">{absentee.empName.charAt(0)}</span>
                      <div className="absence-info">
                        <p>{absentee.empName}</p>
                        <p>{new Date(absentee.fromDate).toLocaleDateString()} → {new Date(absentee.toDate).toLocaleDateString()}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No one’s off today! 🎉</p>
              )}
            </CardBody>

        </Card>
      </Col>
      <Col md="4">
        <Card className="dashboard-card card-light-green">
          <CardHeader><h5>Yet to Punch In ⏳</h5></CardHeader>
          <CardBody>
              {loadingYetToPunchIn ? (
                <div className="text-center">
                  <Spinner color="primary" />
                </div>
              ) : yetToPunchIn.length > 0 ? (
                <div className="yet-to-punch-in-container">
                  <ul className="yet-to-punch-in-list">
                    {yetToPunchIn.map((employee, index) => (
                      <li key={index} className="yet-to-punch-in-item">
                        <span className="initial">{employee.name.charAt(0)}</span>
                        <div className="employee-info">
                          <p className="name">{employee.name}</p>
                          <p className="job-title">{employee.job_title || "No Job Title"}</p>
                        </div>
                        <Edit
                          size={18}
                          className="edit-icon"
                          style={{ cursor: 'pointer', marginLeft: 'auto' }}
                          onClick={() => toggleVacationModal(employee)}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <p className="all-punched-in">Everyone has punched in today! 🎉</p>
              )}
            </CardBody>


        </Card>
      </Col>
      <Col md="4">
        <Card className="dashboard-card card-green">
          <CardHeader><h5>Documents Expiring Soon 📄</h5></CardHeader>
          <CardBody>
              {loadingDocuments ? (
                <Spinner color="primary" />
              ) : expiry.length > 0 ? (
                <ul>
                  {expiry.map((doc, index) => (
                    <li key={index} className="expiring-document-item">
                      <span className="initial">{doc.owner.charAt(0)}</span>
                      <div>
                        <p>{doc.owner} - {doc.docType}</p>
                        <p>{new Date(doc.expiryDate).toLocaleDateString()}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No documents expiring soon!</p>
              )}
            </CardBody>
        </Card>
      </Col>
    </Row>

    {/* Row 2: Late Punch-Ins / Probation Ending */}
    <Row className="mb-4 g-4">
      <Col md="6">
        <Card className="dashboard-card card-blue">
          <CardHeader><h5>Late Punch-Ins ⏳</h5></CardHeader>
          <CardBody style={{ maxHeight: "250px", overflowY: "auto" }}>
                {loadingLatePunch ? (
                  <div className="text-center">
                    <Spinner color="primary" />
                  </div>
                ) : latePunches.length > 0 ? (
                  <ul style={{ padding: 0, margin: 0, listStyle: "none" }}>
                    {latePunches.map((employee, index) => (
                      <li key={index} className="expiring-document-item">
                        <span
                          className="initial"
                          style={{ backgroundColor: "purple", color: "white" }}
                        >
                          {employee.name.charAt(0)}
                        </span>

                        <div>
                          <p className="name">{employee.name}</p>
                          <p>Late by {employee.lateBy}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No late punch-ins today! 🎉</p>
                )}
              </CardBody>
        </Card>
      </Col>
      <Col md="6">
        <Card className="dashboard-card card-red">
          <CardHeader><h5>Probation Ending in 30 Days 📅</h5></CardHeader>
          <CardBody>
                {loadingProbation ? (
                  <Spinner color="primary" />
                ) : probationEnding.length > 0 ? (
                  <ul>
                    {probationEnding.map((employee, index) => (
                      <li key={index} className="expiring-document-item">
                        <span className="initial">{employee.name.charAt(0)}</span>
                        <div>
                          <p>{employee.name} - Ends on {new Date(employee.end_of_probation).toLocaleDateString()}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No probation periods ending soon.</p>
                )}
              </CardBody>

        </Card>
      </Col>
    </Row>

    {/* Row 3: Birthdays / Calendar */}
    <Row className="g-4">
      <Col md="6">
        <Card className="dashboard-card upcoming-birthdays">
          <CardHeader><h5>Upcoming Birthdays 🎂</h5></CardHeader>
          <CardBody> {loadingBirthdays ? (
                <div className="text-center">
                  <Spinner color="primary" />
                </div>
              ) : upcomingBirthdays.length > 0 ? (
                <ul className="upcoming-birthdays-list">
                  {upcomingBirthdays.map((user, index) => (
                    <li key={index} className="upcoming-birthday-item">
                      <span className="initial">{user.name.charAt(0)}</span>
                      <div className="birthday-info">
                        <p className="name">{user.name}</p>
                        <p className="date">{new Date(user.birthDateThisYear).toLocaleDateString()}</p>
                        <p className="time-left">
                          {user.timeDiff} days left 🎉
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No upcoming birthdays in the next 3 weeks</p>
              )}
</CardBody>
        </Card>
      </Col>
      <Col md="6">
        <Card className="dashboard-card calendar">
          <CardHeader><h5>Calendar 📅</h5></CardHeader>
          <CardBody>
            <Calendar value={selectedDate} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>

      
      {console.log("🔍 vacationModal State Inside JSX:", vacationModal)}
      
      {/* Modal Component */}
      
        <Modal isOpen={vacationModal} toggle={toggleVacationModal}>
    <ModalHeader toggle={toggleVacationModal}>
      {isVacationEditMode ? 'Edit Vacation Info' : 'Add Vacation Info'}
    </ModalHeader>
    <ModalBody>
      <Form onSubmit={handleSaveVacation}>
        {/* Employee Name - Auto-selected */}
        <FormGroup>
          <Label for="empName">Employee Name</Label>
          <Input
            type="text"
            name="empName"
            id="empName"
            value={vacationFormValues[0].empName || ''}
            disabled // Prevent user from changing
          />
        </FormGroup>

        {/* Employee Number - Auto-selected */}
        <FormGroup>
          <Label for="empNo">Employee Number</Label>
          <Input
            type="text"
            name="empNo"
            id="empNo"
            value={vacationFormValues[0].empNo || ''}
            disabled // Prevent user from changing
          />
        </FormGroup>

        {/* From Date - Auto-selected */}
        <FormGroup>
          <Label for="fromDate">From Date</Label>
          <Input
            type="date"
            name="fromDate"
            id="fromDate"
            value={vacationFormValues[0].fromDate || ''}
            disabled // Set to today automatically
          />
        </FormGroup>

        {/* To Date - Auto-selected */}
        <FormGroup>
          <Label for="toDate">To Date</Label>
          <Input
            type="date"
            name="toDate"
            id="toDate"
            value={vacationFormValues[0].toDate || ''}
            disabled // Set to today automatically
          />
        </FormGroup>

        {/* Number of Days - Auto-selected */}
        <FormGroup>
          <Label for="noOfDays">Number Of Days</Label>
          <Input
            type="number"
            name="noOfDays"
            id="noOfDays"
            value={vacationFormValues[0].noOfDays || 1}
            disabled // Always 1 by default
          />
        </FormGroup>

        {/* Transaction Type Dropdown */}
        <FormGroup>
          <Label for="transactionType">Transaction Type</Label>
          <Input
            type="select"
            name="transactionType"
            id="transactionType"
            value={vacationFormValues[0].transactionType}
            onChange={(e) => handleVacationFormChange(e, 0)}
            required
          >
            <option value="" disabled>Select Type</option>
            <option value="ANNUAL VACATIONS">ANNUAL VACATIONS</option>
            <option value="SICK DAY OFF">SICK DAY OFF</option>
            <option value="ABSENCE WITHOUT PERMISSIONS">ABSENCE WITHOUT PERMISSIONS</option>
            <option value="BUSINESS VACATION">BUSINESS VACATION</option>
            <option value="LATE DEDUCTION">LATE DEDUCTION</option>
            <option value="MANUAL ATTENDANCE">MANUAL ATTENDANCE</option>
            <option value="MATERNITY LEAVE">MATERNITY LEAVE</option>
            <option value="PATERNITY LEAVE">PATERNITY LEAVE</option>
            <option value="PERSONAL LEAVE">PERSONAL LEAVE</option>
            <option value="PILGRIMAGE LEAVE">PILGRIMAGE LEAVE</option>
            <option value="SICK LEAVE">SICK LEAVE</option>
            <option value="UNPAID VACATION">UNPAID VACATION</option>
            <option value="EARLY OUT">EARLY OUT</option>
            <option value="HALF DAY">HALF DAY</option>
          </Input>
        </FormGroup>

        {/* Status Dropdown */}
        <FormGroup>
          <Label for="status">Status</Label>
          <Input
            type="select"
            name="status"
            id="status"
            value={vacationFormValues[0].status}
            onChange={(e) => handleVacationFormChange(e, 0)}
            required
          >
            <option value="Pending">Pending</option>
            <option value="In_Progress">In Progress</option>
            <option value="Completed">Completed</option>
            <option value="Canceled">Canceled</option>
          </Input>
        </FormGroup>

        <ModalFooter>
          <Button color="primary" type="submit">
            {isVacationEditMode ? 'Update' : 'Save'}
          </Button>
          <Button color="secondary" onClick={toggleVacationModal}>Cancel</Button>
        </ModalFooter>
      </Form>
    </ModalBody>
    </Modal>




      {/* Modal for updating time off
      <Modal isOpen={modal} toggle={() => toggleModal(null)}>
        <ModalHeader toggle={() => toggleModal(null)}>Update Time Off</ModalHeader>
        <ModalBody>
          {selectedEmployee && (
            <Form>
              <FormGroup>
                <Label>Employee</Label>
                <Input type="text" value={selectedEmployee.name} disabled />
              </FormGroup>
              <FormGroup>
                <Label>Time Off Type</Label>
                <Input type="select" value={timeOffType} onChange={e => setTimeOffType(e.target.value)}>
                  <option value="">Select Type</option>
                  <option value="ANNUAL VACATIONS">Annual Vacation</option>
                  <option value="SICK LEAVE">Sick Leave</option>
                  <option value="UNPAID VACATION">Unpaid Vacation</option>
                  <option value="MATERNITY LEAVE">Maternity Leave</option>
                  <option value="PATERNITY LEAVE">Paternity Leave</option>
                  <option value="PILGRIMAGE LEAVE">Pilgrimage Leave</option>
                  <option value="HALF DAY">Half Day</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>Number of Days</Label>
                <Input type="number" value={noOfDays} min="0.5" step="0.5" onChange={e => setNoOfDays(e.target.value)} />
              </FormGroup>
            </Form>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleTimeOffSubmit}>Submit</Button>
          <Button color="secondary" onClick={() => toggleModal(null)}>Cancel</Button>
        </ModalFooter>
      </Modal> */}
    </Fragment>
  );
};

export default HrmsAdminDashboard;