import React, { useEffect, useState } from 'react';
import {
    Button,
    Input,
    Card,
    CardBody,
    CardHeader,
    Form,
    FormGroup,
    Label,
    Alert,
} from 'reactstrap';

import { toast } from 'react-toastify';

import { getUserOptions } from 'Services/user.service';
import { UsersPersonalDetail } from 'Services/usersPersonalDetail.service';
import { fetchemployeeS } from 'Services/employeeS.service';
import { VacationDays } from 'Services/vacationdays.service';

const Calculator = () => {
    const [users, setUsers] = useState([]);
    const [userDetails, setUserDetails] = useState([]);
    const [salaryData, setSalaryData] = useState([]);
    const [vacationData, setVacationData] = useState([]);

    const [selectedUserId, setSelectedUserId] = useState('');
    const [joiningDate, setJoiningDate] = useState('');
    const [lastWorkingDate, setLastWorkingDate] = useState('');
    const [salary, setSalary] = useState('');
    const [leavesPaid, setLeavesPaid] = useState('');
    const [leaveData, setLeaveData] = useState(null);
    const [gratuity, setGratuity] = useState(null);
    const [tenure, setTenure] = useState('');
    const [details, setDetails] = useState([]);
    const [totalS, setTotalS] = useState('');
    const [lastMonthSalary, setLastMonthSalary] = useState(null);
    const [totalEndOfService, setTotalEndOfService] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const [userRes, detailsRes, salaryRes, vacRes] = await Promise.all([
                    getUserOptions(),
                    UsersPersonalDetail(),
                    fetchemployeeS(),
                    VacationDays(),
                ]);



                if (userRes?.data?.data) {
                    const formatted = userRes.data.data.map(u => ({
                        value: u.id,
                        label: `${u.firstName} ${u.lastName}`,
                    }));
                    setUsers(formatted);
                }

                setUserDetails(detailsRes || []);
                setSalaryData(Array.isArray(salaryRes?.rows) ? salaryRes.rows : []);
                setVacationData(Array.isArray(vacRes) ? vacRes : []);
            } catch (err) {
                console.error(err);
                toast.error("Error loading data");
            }
        };

        fetchData();
    }, []);



    const handleUserSelect = (e) => {
        const userId = e.target.value;
        setSelectedUserId(userId);

        const user = userDetails.find(u => u.userid === userId);
        if (!user) {
            toast.error("Employee not found.");
            return;
        }

        setJoiningDate(user.hiring_date);
        console.log('USERID', userId);

        const empSalary = salaryData.find(s => s.empNo === Number(userId));

        if (!empSalary) {
            toast.warn("No salary data found for this employee.");
            setSalary(0);
            setTotalS(0);
        } else {
            const salaryValue = parseFloat(empSalary.basicS || 0);
            const totalSalaryValue = parseFloat(empSalary.totalS || 0);
            setSalary(salaryValue);
            setTotalS(totalSalaryValue);
        }



        const unpaidDays = vacationData
            .filter(v => v.empNo === userId && ['UNPAID VACATION', 'ABSENCE WITHOUT PERMISSIONS'].includes(v.transactionType))
            .reduce((sum, vac) => sum + parseFloat(vac.noOfDays || 0), 0);

        setLeavesPaid(unpaidDays);

    };

    const calculateLastMonthSalary = () => {
        if (!lastWorkingDate || !totalS) {
            toast.error("Last Working Date and Total Salary are required.");
            return;
        }

        const lastDate = new Date(lastWorkingDate);
        const startOfMonth = new Date(lastDate.getFullYear(), lastDate.getMonth(), 1);
        const daysWorked = lastDate.getDate(); // From 1st till the last working day

        const dailyRate = totalS / 30.41666666666667;
        const salaryForDays = (dailyRate * daysWorked).toFixed(2);

        setLastMonthSalary(salaryForDays);
    };

    const calculateLeave = () => {
        if (!joiningDate || !lastWorkingDate) {
            toast.error("Please fill in all fields.");
            return;
        }

        const startDate = new Date(joiningDate);
        const endDate = new Date(lastWorkingDate);

        if (endDate <= startDate) {
            toast.error("Last working day must be after the joining date.");
            return;
        }

        const totalDaysService = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
        const yearsOfService = (totalDaysService / 365).toFixed(2);
        const totalLeaveBenefit = ((totalDaysService * 30) / 365).toFixed(2);
        const leavesPaidOffset = parseFloat(leavesPaid) || 0;
        const netLeaveDaysDue = (totalLeaveBenefit - leavesPaidOffset).toFixed(2);
        const dailySalaryRate = ((salary * 12) / 365).toFixed(2);
        const totalLeaveSalaryDue = (netLeaveDaysDue * dailySalaryRate).toFixed(2);

        setLeaveData({
            totalDaysService,
            yearsOfService,
            totalLeaveBenefit,
            leavesPaidOffset,
            netLeaveDaysDue,
            dailySalaryRate,
            totalLeaveSalaryDue,
        });
    };

    const calculateGratuity = () => {
        if (!joiningDate || !lastWorkingDate) {
            toast.error("Please fill in all fields.");
            return;
        }

        const startDate = new Date(joiningDate);
        const endDate = new Date(lastWorkingDate);

        if (endDate <= startDate) {
            toast.error("Last working day must be after joining date.");
            return;
        }

        const basicSalaryPerDay = salary / 30;
        const gratuityDetails = [];
        let totalGratuity = 0;
        let currentStart = new Date(startDate);
        let totalDays = 0;

        while (currentStart < endDate) {
            const nextYear = new Date(currentStart);
            nextYear.setFullYear(currentStart.getFullYear() + 1);

            const periodEnd = nextYear < endDate ? nextYear : endDate;
            const periodDays = (periodEnd - currentStart) / (1000 * 60 * 60 * 24);
            totalDays += periodDays;

            let gratuityDays = totalDays < 365
                ? 0
                : totalDays < 5 * 365
                    ? (periodDays / 365) * 21
                    : ((periodDays - Math.max(totalDays - 5 * 365, 0)) / 365) * 21 + (Math.max(totalDays - 5 * 365, 0) / 365) * 30;

            const cappedGratuity = Math.min(gratuityDays, totalDays > 5 * 365 ? 30 : 21) * basicSalaryPerDay;
            const periodGratuity = Math.round(cappedGratuity);

            gratuityDetails.push({
                periodFrom: currentStart.toISOString().split('T')[0],
                periodTo: periodEnd.toISOString().split('T')[0],
                days: Math.round(periodDays),
                gratuity: Math.round(periodGratuity),
            });

            totalGratuity += periodGratuity;
            currentStart = periodEnd;
        }

        const years = Math.floor(totalDays / 365);
        const months = Math.floor((totalDays % 365) / 30);
        const days = Math.floor((totalDays % 365) % 30);

        setTenure(`${years} years, ${months} months, and ${days} days`);
        setGratuity(totalGratuity);
        setDetails(gratuityDetails);
    };
    const calculateTotalEndOfService = () => {
        const leaveSalary = parseFloat(leaveData?.totalLeaveSalaryDue || 0);
        const gratuityAmt = parseFloat(gratuity || 0);
        const lastMonth = parseFloat(lastMonthSalary || 0);

        const total = (leaveSalary + gratuityAmt + lastMonth).toFixed(2);
        setTotalEndOfService(total);
    };


    return (
        <Card>
            <CardHeader>
                <h3>End Of Service Calculator</h3>
            </CardHeader>
            <style>
                {`
    .form-group.w-75 {
      display: none;
    }
    .form-inline.search-fulls.col-7 {
        border: none;
    }
    .MuiBox-root.css-19midj6 {
        padding: 3px;
    }
    `}
            </style>
            <CardBody>
                <Form>
                    <FormGroup>
                        <Label for="user">Select Employee</Label>
                        <Input type="select" id="user" value={selectedUserId} onChange={handleUserSelect}>
                            <option value="">Select an employee</option>
                            {users.map(opt => (
                                <option key={opt.value} value={opt.value}>
                                    {opt.label}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <Label for="joiningDate">Joining Date</Label>
                        <Input type="date" id="joiningDate" value={joiningDate} readOnly />
                    </FormGroup>

                    <FormGroup>
                        <Label for="lastWorkingDate">Last Working Date</Label>
                        <Input type="date" id="lastWorkingDate" value={lastWorkingDate} onChange={(e) => setLastWorkingDate(e.target.value)} />
                    </FormGroup>

                    <Button color="primary" onClick={calculateLeave}>Calculate Leave</Button>
                    <Button color="secondary" className="ml-2" onClick={calculateGratuity}>Calculate Gratuity</Button>
                    <Button color="warning" className="ml-2" onClick={calculateLastMonthSalary}>Calculate Last Month Salary</Button>
                    <Button color="success" className="ml-2" onClick={calculateTotalEndOfService}>Calculate Total End of Service</Button>

                </Form>

                {leaveData && (
                    <Alert color="success" className="mt-4">
                        <strong>Total Leave Salary Due:</strong> AED {leaveData.totalLeaveSalaryDue}
                    </Alert>
                )}

                {gratuity !== null && (
                    <Alert color="info" className="mt-4">
                        <strong>Total Gratuity:</strong> AED {gratuity}<br />
                        <strong>Total Tenure:</strong> {tenure}
                    </Alert>
                )}
                {lastMonthSalary && (
                    <Alert color="warning" className="mt-4">
                        <strong>Last Month Salary:</strong> AED {lastMonthSalary}
                    </Alert>
                )}

                {totalEndOfService && (
                    <Alert color="dark" className="mt-4">
                        <strong>Total End of Service Amount:</strong> AED {totalEndOfService}
                    </Alert>
                )}

            </CardBody>
        </Card>
    );
};

export default Calculator;
