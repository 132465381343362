import React, { Fragment, useEffect, useState } from 'react';
import { documentsService } from 'Services';
import { Table } from 'reactstrap';
import { toast } from "react-toastify";

import TableLoader from 'CommonElements/Table/TableLoader';
import TableNodata from 'CommonElements/Table/TableNodata';
import TableRowStatus from 'CommonElements/Table/TableRowStatus';

import DocumentsUpload from './DocumentsUpload';
import DeletDocument from './DeletDocument';
import ApproveDocument from './ApproveDocument';
import RejectDocument from './RejectDocument';

import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';
import { formatTimeInDubaiZone } from 'Components/Common/Utility';

const Documents = (props) => {
  const {
    customerId = '',
    activeTab, tabNumber
  } = props;
  const [rec, setRec] = useState({});
  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  );
  useEffect(() => {
    if (activeTab === tabNumber) {
      loadData();
    }
  }, [activeTab, tabNumber]);
  const loadData = () => {
    documentsService.getDocuments(customerId).then((res) => {
      setRec({
        ...res,
        loading: false,
      })
    }).catch(err => {
    });
  }

  const getUrl = async (item, num = 1) => {
    console.log('item.imageUrl', item.imageUrl);
    console.log('item.imageUrl', item);
    documentsService.getDocumentSas(item.id, num).then(res => {
      if (res && res.url) {
        window.open(res.url, '_blank')
      } else {
        toast.error('Failed to generate Link');
      }

    }).catch(err => {
      toast.error('Failed to generate Link');

    })
    return false;
  }
  return (
    <Fragment>
      {getMenuPerm(permissions, 'documents:create') === 1 &&
        <DocumentsUpload customerId={customerId} loadData={loadData} />
      }
      <div className="table-responsive">
        <Table hover striped>
          <thead>
            <tr>
              <th scope="col">{'#'}</th>
              <th scope="col">{'Type'}</th>
              <th scope="col">{'Files'}</th>
              <th scope="col">{'Note'}</th>
              <th scope="col">{'Upload Time'}</th>
              <th scope="col">{'Modified Time'}</th>
              {/* <th scope="col">{'Rejection Reason'}</th> */}
              <th scope="col">{'Uploaded By'}</th>
              <th scope="col">{'Status'}</th>
              <th scope="col">{'Actions'}</th>

            </tr>
          </thead>
          {rec.loading && <TableLoader />}
          {!rec.loading && rec.data && rec.data.length === 0 && <TableNodata title="Documents" />}

          <tbody>
            {
              rec.data && rec.data.map((item, id) =>
                <tr key={id}>
                  <th scope="row">{item.id}</th>
                  <td >{item.type}</td>
                  <td >
                    {/* <a className='m-3' href={process.env.REACT_APP_IMAGE_URL+item.file1} target='_blank' rel="noreferrer">File1</a>
                                    {item.file2 && 
                                        <a className='m-3' href={process.env.REACT_APP_IMAGE_URL+item.file2} target='_blank' rel="noreferrer">File2</a>
                                    } */}
                    <p className='m-0 text-decoration-underline' style={{ cursor: 'pointer' }} onClick={() => { getUrl(item, 1) }} target='_blank' rel="noreferrer">File1</p>
                    {item.file2 &&
                      <p className='m-0 text-decoration-underline' style={{ cursor: 'pointer' }} onClick={() => { getUrl(item, 2) }} target='_blank' rel="noreferrer">File2</p>
                    }
                    {item.file3 &&
                      <p className='m-0 text-decoration-underline' style={{ cursor: 'pointer' }} onClick={() => { getUrl(item, 3) }} target='_blank' rel="noreferrer">File3</p>
                    }
                    {item.file4 &&
                      <p className='m-0 text-decoration-underline' style={{ cursor: 'pointer' }} onClick={() => { getUrl(item, 4) }} target='_blank' rel="noreferrer">File4</p>
                    }
                    {item.file5 &&
                      <p className='m-0 text-decoration-underline' style={{ cursor: 'pointer' }} onClick={() => { getUrl(item, 5) }} target='_blank' rel="noreferrer">File5</p>
                    }

                  </td>
                  <td >{item.note || item.rejectionReason}</td>
                  <td >{formatTimeInDubaiZone(item.createdAt,1)}</td>
                  <td >{formatTimeInDubaiZone(item.updatedAt,1)}</td>
                  {/* <td >{new Date(item.createdAt).toUTCString()}</td> */}
                  {/* <td >{new Date(item.updatedAt).toUTCString()}</td> */}

                  {/* <td >{item.rejectionReason}</td> */}
                  <td >{item.createdBy}</td>
                  <td >
                    <TableRowStatus status={item.status} />
                  </td>
                  <td>
                    {(item.status === 'Pending') && <>
                      {getMenuPerm(permissions, 'documents:actions') === 1 &&
                        <ApproveDocument data={item} loadData={loadData} />
                      }
                      {getMenuPerm(permissions, 'documents:actions') === 1 &&
                        <RejectDocument data={item} loadData={loadData} />
                      }
                      {getMenuPerm(permissions, 'documents:delete') === 1 &&
                        <DeletDocument data={item} loadData={loadData} />
                      }
                    </>}
                    {
                      (item.status !== 'Pending') && <>
                        {getMenuPerm(permissions, 'documents:delete') === 1 &&
                          <DeletDocument data={item} loadData={loadData} />
                        }
                      </>
                    }
                  </td>

                </tr>
              )
            }
          </tbody>
        </Table>
      </div>
    </Fragment>
  );
};
export default Documents;