import React, { useState, useEffect } from 'react';
import { Table, Card, CardBody, CardHeader } from 'reactstrap';
import { activitiesService } from 'Services';
import { toast } from 'react-toastify';

const ActivitiesPage = () => {
    const [activities, setActivities] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});


    const fetchActivities = async () => {
        try {
            const res = await activitiesService.getActivities({ level: '1' });
            console.log('Filtered Activities (level=1):', res);
            setActivities(res.data); // ✅ assuming res.data contains the array

        } catch (error) {
            console.error('Failed to load activities:', error);
            toast.error('Failed to load activities');
        }
    };

    useEffect(() => {
        fetchActivities();
    }, []);

    return (
        <Card>
            <CardHeader>
                <h3 style={{ fontSize: '24px', fontWeight: 'bold', color: '#194995', marginBottom: '10px' }}>
                    Activities
                </h3>
            </CardHeader>
            <style>
                {`
                    .form-group.w-75 {
                        display: none;
                    }
                    .form-inline.search-fulls.col-7 {
                        border: none;
                    }
                    .MuiBox-root.css-19midj6 {
                        padding: 3px;
                    }
                `}
            </style>
            <CardBody>
                <Table bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Content</th>
                            <th>User ID (Modifier)</th>
                            <th>User ID (Changed)</th>
                            <th>Changed At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {activities.map(activity => {
                            const isExpanded = expandedRows[activity.id] || false;

                            return (
                                <tr key={activity.id}>
                                    <td>{activity.type}</td>
                                    <td style={{ whiteSpace: 'pre-wrap', maxWidth: '400px' }}>
                                        <div>
                                            <pre style={{ maxHeight: isExpanded ? 'none' : '50px', overflow: 'hidden', background: '#f8f9fa', padding: '10px', borderRadius: '4px' }}>
                                                {JSON.stringify(activity.content, null, 2)}
                                            </pre>
                                            <button
                                                onClick={() =>
                                                    setExpandedRows(prev => ({
                                                        ...prev,
                                                        [activity.id]: !isExpanded
                                                    }))
                                                }
                                                style={{
                                                    background: 'none',
                                                    border: 'none',
                                                    color: '#007bff',
                                                    cursor: 'pointer',
                                                    padding: 0,
                                                    marginTop: '5px'
                                                }}
                                            >
                                                {isExpanded ? 'Show less' : 'Show more'}
                                            </button>
                                        </div>
                                    </td>
                                    <td>{activity.userId}</td>
                                    <td>{activity.customerId}</td>
                                    <td>{new Date(activity.createdAt).toLocaleString()}</td>
                                </tr>
                            );
                        })}
                    </tbody>

                </Table>
            </CardBody>
        </Card>
    );
};

export default ActivitiesPage;
