import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row, Media, CardHeader, Table } from 'reactstrap';
import { H4 } from '../../../../../AbstractElements';
import { dashboardService } from "Services";
import Select from "react-select";
import moment from 'moment';
import { useSelector } from "react-redux";
import ibAccount from '../../../../../assets/images/avtar/ib-account.svg'
import { formatMoney } from 'Components/Common/Utility';
import withdrawalss from '../../../../../assets/images/avtar/withdrawals.svg';
const getDates = () => {
  const newDate = moment();
  const dateToday = newDate.format('YYYY-MM-DD');
  const dateTomorrow = moment().add(1, 'days').format('YYYY-MM-DD');

  const dateStartOfMonth = moment().startOf('month').format('YYYY-MM-DD');
  const dateStartOfLastMonth = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
  const dateEndOfLastMonth = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
  const dateStartofWeek = moment().startOf('week').format('YYYY-MM-DD');
  const dateStartofYear = moment().startOf('year').format('YYYY-MM-DD');

  return {
    dateToday,
    dateStartOfMonth,
    dateStartOfLastMonth,
    dateEndOfLastMonth,
    dateTomorrow,
    dateStartofWeek,
    dateStartofYear,
  };
};


const SmallApexChart = ({ ibAccounts = {}, withdrawals = {}, depositsSales = [], customerId, show }) => {
  const [depositsByDate, setDepositsByDate] = useState({});
  const [filteredData, setFilteredData] = useState(depositsSales);
  const [rec, setRec] = useState({});

  const roleCountry = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.country) || {}
  );

  const datesObj = getDates();
  // fetching deposit between today and yesterday
  const loadData = (dateFrom = datesObj.dateToday, dateTo = datesObj.dateTomorrow) => {
    console.log('date range: ', dateFrom, dateTo);
    dashboardService.getDepositsByDate(dateFrom, dateTo).then(res => {
      setDepositsByDate(res);
    }).catch(err => {
    });
  }
  useEffect(() => {
    loadData();
    //loadNotes();
  }, []);

  const [filter, setFilter] = useState('');
  const handleFilterChange = (event) => {
    const inputValue = event.target.value;
    setFilter(inputValue);
  };
  useEffect(() => {
    // Filter depositsSales based on the input value
    const newFilteredData = depositsSales && depositsSales.filter((item) => {
      return item && item['Customer.Agent.firstName'] &&
        item['Customer.Agent.firstName'].toLowerCase().includes(filter.toLowerCase());
    });
    // Update the filtered data
    setFilteredData(newFilteredData);
  }, [depositsSales, filter]);


  // Calculate totals for each column
  const totals = filteredData.reduce((acc, item) => {
    acc.totalFtDepositOfMonth += parseInt(item['Customer.totalFtDepositOfMonth']) || 0;
    acc.totalFtDepositLastMonth += parseInt(item['Customer.totalFtDepositLastMonth']) || 0;
    acc.currentMonth += (item.currentMonth) || 0;
    acc.lastMonth += parseInt(item.lastMonth) || 0;
    return acc;
  }, { totalFtDepositOfMonth: 0, totalFtDepositLastMonth: 0, currentMonth: 0, lastMonth: 0 });


  const DataTable = ({ filteredData }) => {
    //const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
    const [sortConfig, setSortConfig] = useState({ key: 'Customer.Agent.firstName', direction: 'asc' });

    const handleSort = (key) => {
      let direction = 'asc';
      if (sortConfig.key === key && sortConfig.direction === 'asc') {
        direction = 'desc';
      }
      setSortConfig({ key, direction });
    };
    const getArrow = (key) => {
      if (sortConfig.key === key) {
        return sortConfig.direction === 'asc' ? '▲' : '▼';
      }
      return '';
    };

    const sortedData = [...filteredData].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
    return (
      <Table hover striped>
        <thead>
          <tr style={{ verticalAlign: 'top' }}>
            <th scope="col pointer" onClick={() => handleSort('Customer.Agent.firstName')}>Name {getArrow('Customer.Agent.firstName')}</th>
            <th scope="col pointer" onClick={() => handleSort('Customer.totalFtDepositOfMonth')}>Current Month FTD {getArrow('Customer.totalFtDepositOfMonth')}</th>
            <th scope="col pointer" onClick={() => handleSort('Customer.totalFtDepositLastMonth')}>Last Month FTD {getArrow('Customer.totalFtDepositLastMonth')}</th>
            <th scope="col pointer" onClick={() => handleSort('currentMonth')}>Current Month Deposits {getArrow('currentMonth')}</th>
            <th scope="col pointer" onClick={() => handleSort('lastMonth')}>Last Month Deposits {getArrow('lastMonth')}</th>
          </tr>
        </thead>
        <tbody>
          {sortedData &&
            sortedData.map((item, id) => (
              <tr key={id}>
                <td className="saleName">{item['Customer.Agent.firstName']} {item['Customer.Agent.lastName']}</td>
                <td>{item['Customer.totalFtDepositOfMonth'] ? formatMoney(parseInt(item['Customer.totalFtDepositOfMonth'])) : '0'} $</td>
                <td>{item['Customer.totalFtDepositLastMonth'] ? formatMoney(parseInt(item['Customer.totalFtDepositLastMonth'])) : '0'} $</td>
                <td>{item.currentMonth ? formatMoney(parseInt(item.currentMonth)) : '0'} $</td>
                <td>{item.lastMonth ? formatMoney(parseInt(item.lastMonth)) : '0'} $</td>
              </tr>
            ))}
          {/* Totals row */}
          <tr>
            <td><strong>Total</strong></td>
            <td>{formatMoney(parseInt(totals.totalFtDepositOfMonth))} $</td>
            <td>{formatMoney(parseInt(totals.totalFtDepositLastMonth))} $</td>
            <td>{formatMoney(parseInt(totals.currentMonth))} $</td>
            <td>{formatMoney(parseInt(totals.lastMonth))} $</td>
          </tr>
        </tbody>
      </Table>
    );
  };
  return (
    <>
      <Col xl={roleCountry && (roleCountry === 'Morocco' || show) ? '12' : '4'} style={{ padding: '0px', display: roleCountry && (roleCountry === 'Morocco' || show) ? 'flex' : 'block', }}>
        <Col xl={roleCountry && (roleCountry === 'Morocco' || show) ? '4' : '12'} className={`chart_data_rights `}>
          <Card className='color_4'>
            <div className='p-3 card-headers medium'>
              IB Accounts
            </div>
            <CardBody>
              {ibAccounts &&
                <Media className='align-items-center'>
                  <Media body className='right-chart-content'>
                    <div className='flex gap_20'>
                      <div>
                        <img src={ibAccount} />
                      </div>
                      <div>
                        <span className='f_24 semibold'>{ibAccounts.currentMonth || 0}</span><br />
                        <span className='new-boxs gray'>{'Month to Date'}</span>
                      </div>
                    </div>
                    <br /><br />
                    <div className='flex justify-between'>
                      <div>
                        <span className='gray'>{'Last Month'}</span><br />
                        <span className='f_20 semibold'>{ibAccounts.lastMonth ? Number(ibAccounts.lastMonth) : '0'}</span>
                      </div>
                      <div>
                        <span className='gray'>{'Today'}</span><br />
                        <span className='f_20 semibold'>{ibAccounts.today || 0}</span>
                      </div>
                    </div>
                  </Media>
                </Media>
              }
            </CardBody>
          </Card>
        </Col>
        <Col xl={roleCountry && (roleCountry === 'Morocco' || show) ? '4' : '12' + ' xl-33'} className={`chart_data_rights `}>
          <Card className='color_5'>
            <div className='p-3 card-headers'>
              Withdrawals
            </div>
            <CardBody>
              {withdrawals &&
                <Media className='align-items-center'>
                  <Media body className='right-chart-content'>
                    <div className='flex gap_20'>
                      <div>
                        <img src={withdrawalss} />
                      </div>
                      <div>
                        <span className='f_24 semibold'>{withdrawals.currentMonth ? formatMoney(parseInt(withdrawals.currentMonth)) + '$' : '0'}</span><br />
                        <span className='new-boxs gray'>{'Month to Date'}</span>
                      </div>
                    </div>
                    <br /><br />
                    <div className='flex justify-between'>
                      <div>
                        <span className='gray'>{'Last Month'}</span><br />
                        <span className='f_20 semibold'>{withdrawals.lastMonth ? formatMoney(parseInt(withdrawals.lastMonth)) + '$' : '0'}</span>
                      </div>
                      <div>
                        <span className='gray'>{'Today'}</span><br />
                        <span className='f_20 semibold'>{withdrawals.today ? formatMoney(parseInt(withdrawals.today).toFixed(2)) + '$' : '0'}</span>
                      </div>
                    </div>
                  </Media>
                </Media>
              }
            </CardBody>
          </Card>
        </Col>
        <Col xl={roleCountry && (roleCountry === 'Morocco' || show) ? '4' : '12' + ' xl-33'} className={`chart_data_right `}>
          <Card style={{ 'overflow': 'visible' }}>
            <CardHeader className='p-3'>
              Deposits
            </CardHeader>
            <CardBody className='p-3'>
              <Row>

                <Col>
                  <Select
                    classNamePrefix="addl-class"
                    defaultValue={{ label: 'Today', value: `${datesObj.dateToday}:${datesObj.dateTomorrow}` }}
                    options={[
                      { label: 'Today', value: `${datesObj.dateToday}:${datesObj.dateTomorrow}` },
                      { label: 'This Week', value: `${datesObj.dateStartofWeek}:${datesObj.dateTomorrow}` },
                      { label: 'This Month', value: `${datesObj.dateStartOfMonth}:${datesObj.dateTomorrow}` },
                      { label: 'Last Month', value: `${datesObj.dateStartOfLastMonth}:${datesObj.dateStartOfMonth}` },
                      { label: 'This Year', value: `${datesObj.dateStartofYear}:${datesObj.dateTomorrow}` },
                    ]}
                    // value={newOptions.find((c) => c.value === value)}
                    isMulti={false}
                    placeholder={'Select Time Span'}
                    onChange={(e) => {
                      const [dateFrom, dateTo] = e.value.split(':');
                      loadData(dateFrom, dateTo);
                    }}
                  />
                </Col>

              </Row><br />
              <Row>
                <Col>
                  {depositsByDate &&
                    <Media className='align-items-center'>
                      <Media body className='right-chart-content'>
                        <H4>
                          <div>
                            {depositsByDate.total ? formatMoney(parseInt(depositsByDate.total)) : '0'} $
                          </div>
                        </H4>
                      </Media>
                    </Media>
                  }
                </Col>
              </Row>

            </CardBody>
          </Card>
        </Col>
      </Col>
      <Col xl='8' style={{
        display: roleCountry && (roleCountry === 'Morocco' || show) ? 'none' : 'block',
        padding: '0px'
      }}
      >
        <Col className={`chart_data_right `}>
          <Card>
            <CardHeader className='p-3 d-flex align-items-center justify-content-between'>
              <p style={{ margin: '0px' }}>Deposit By Sales</p>
              <input
                className="form-control"
                type="text"
                value={filter}
                style={{ width: '50%' }}
                onChange={handleFilterChange}
                placeholder="Filter by name..."
              />
            </CardHeader>
            <CardBody>
              <div className="table-responsive">
                <DataTable filteredData={filteredData} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Col>

    </>
  );
};

export default SmallApexChart;
