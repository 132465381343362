import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardBody, Form, Row, ListGroupItem, ListGroup, Input, Label } from 'reactstrap';
import { Btn } from '../../../../AbstractElements';
import { useForm } from 'react-hook-form';
import { FInput, FSelect, FCheckbox, FUserSelect, FDateTime, FGroupSelect } from 'CommonElements/Form';
import { clientService, groupsService, activitiesService } from 'Services';
import SweetAlert from 'sweetalert2';
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';
import Notes from '../Notes/Notes';
import countriesStates from '../../../Common/json/countriesStates.json';
import { idTypeCodes, sourceOfFundCodes, modeOfPaymentCodes } from '../../../Common/json/amlShortCode.js';
import { toast } from 'react-toastify';
import GroupNotes from '../Notes/GroupNotes';

const BasicInformation = (props) => {
    const {
        customerId = '',
        data = {},
    } = props;
    // console.log("data for clients is ", data);
    //   const COUNTRIES = useSelector(
    //     (state) => (state.config.constants.COUNTRIES )
    //   );
    const [submitState, setSubmitState] = useState({
        loading: false,
    });
    const [chkTrade, setChkTrade] = useState(false);
    const [coldCall, setcoldCall] = useState(false);
    const setFlagToTrue = () => {
        setcoldCall(true);
    };
    const [formattedDob, setFormattedDob] = useState('');
    const [list, setList] = useState([]);
    const [rec, setRec] = useState({});
    const permissions = useSelector(
        (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
    );

    const userPermissions = useSelector(
        (state) => (state.login && state.login.profile) || {}
    );

    // const rolePermission = useSelector(
    //     (state) => (state.login && state.login.profile.Role) || {}
    // );

    const { register, handleSubmit, formState: { errors }, setValue, getValues, control } = useForm({
        defaultValues: data // assume `data` contains initial values
    });

    useEffect(() => {
        const formFields = getValues();
        loadData(1);
        Object.keys(formFields).forEach(key => {
            if (data[key]) {
                let datas = data[key];
                key === 'country' ? setValue(key, datas.toUpperCase()) : key === 'nationality' ? setValue(key, datas.toUpperCase()) :
                    setValue(key, data[key])
            }
        })
    }, [data]);

    // console.log('conent data: ', data);

    const loadData = (page = 1) => {
        setRec({
            loading: true,
            data: [],
        });
        groupsService
            .getGroups({
                page,
            })
            .then((res) => {
                setRec({
                    ...res,
                    loading: false,
                });
            })
            .catch((err) => {
            });
    };

    const updateAccountStatus = (data) => {
        switch (data.approvalStage) {
            case "Documents Approved":
            case "Pending Documents":
                data.accountStatus = "Pending";
                break;
            case "KYC / CDD Approved":
                data.accountStatus = "Approved";
                data.kycStatus = "approved";
                break;
            case "Trade Enabled/Active":
                data.accountStatus = "Active";
                data.kycStatus = "approved";
                break;
            default:
                // Optionally handle cases where approvalStage doesn't match any of the above
                data.accountStatus = data.accountStatus;
                break;
        }
        return data;
    };

    // const onSubmit = data => {
    //     if (data !== '') {
    //         setSubmitState({ loading: true });
    //         delete data.createdAt;
    //         delete data.updatedAt;
    //         delete data.ftDepositAmount;
    //         delete data.netDepositInUSD;
    //         delete data.ftDepositTime;
    //         delete data.depositInUSD;
    //         delete data.withdrawInUSD;
    //         delete data.lastDepositDate;
    //         chkTrade && (data.tradeDisabled = dbTradeDisable || false);
    //         data.complianceFailed = dbComplianceFailed || false;
    //         data.screenFailed = dbScreeningFailed || false;
    //         predioticDate && (data.predioticDate = predioticDate);
    //         predioticDate && (data.complianceCompleted = new Date());

    //         updateAccountStatus(data);
    //         console.log("data is ", data);

    //         data.idTypeCode = idTypeCodes[data.idType];
    //         data.sourceOfFundsCode = sourceOfFundCodes[data.sourceOfFunds];
    //         data.modeOfPaymentCode = modeOfPaymentCodes[data.modeOfPayment];
    //         data.countryCode = data.country && `${countriesStates.filter(ctry => ctry.name === data.country)[0].iso2}`;
    //         data.nationalityCode = data.nationality && `${countriesStates.filter(ctry => ctry.name === data.nationality)[0].iso2}`

    //         const logData = {
    //             type: `infomation Updated by ${userPermissions.id}`,
    //             customerId: customerId,
    //             note: `${JSON.stringify(data)}`
    //         };

    //         activitiesService.postActivity(logData);

    //         clientService.updateClient(customerId, data).then(res => {
    //             setSubmitState({
    //                 loading: false,
    //                 status: 'Profile updated sucessfully'
    //             })
    //             SweetAlert.fire({ title: 'Success', text: 'Profile updated sucessfully!', icon: 'success' }).then(() => {
    //                 // Refresh the page after the alert is closed
    //                 window.location.reload();
    //             });
    //         }).catch(err => {
    //             setSubmitState({
    //                 loading: false,
    //                 status: 'Error in updating Profile'
    //             })
    //             SweetAlert.fire({ title: 'Failed', text: err.response.data.message, icon: 'error' });
    //         });
    //         // alert('You submitted the form and stuff!');
    //     } else {
    //         errors.showMessages();
    //     }
    // };

    const onSubmit = formData => {
        if (formData !== '') {
            setSubmitState({ loading: true });
            delete formData.createdAt;
            delete formData.updatedAt;
            delete formData.ftDepositAmount;
            delete formData.netDepositInUSD;
            delete formData.ftDepositTime;
            delete formData.depositInUSD;
            delete formData.withdrawInUSD;
            delete formData.lastDepositDate;
            delete formData.Agent;
            delete formData.Transactions;
            delete formData.complianceDate;
            delete formData.Parent;
            delete formData.Group;
            delete formData.Retention;
            chkTrade && (formData.tradeDisabled = dbTradeDisable || false);
            formData.complianceFailed = dbComplianceFailed || false;
            formData.screenFailed = dbScreeningFailed || false;
            predioticDate && (formData.predioticDate = predioticDate);
            predioticDate && (formData.complianceCompleted = new Date());

            updateAccountStatus(formData);
            // console.log("formData is ", formData);

            formData.idTypeCode = idTypeCodes[formData.idType];
            formData.sourceOfFundsCode = sourceOfFundCodes[formData.sourceOfFunds];
            formData.modeOfPaymentCode = modeOfPaymentCodes[formData.modeOfPayment];
            formData.countryCode = formData.country && `${countriesStates.filter(ctry => ctry.name === formData.country)[0].iso2}`;
            formData.nationalityCode = formData.nationality && `${countriesStates.filter(ctry => ctry.name === formData.nationality)[0].iso2}`

            // Step 1: Compare form data with the original `data` object
            const changedData = {};
            Object.keys(formData).forEach(key => {
                if (formData[key] !== data[key]) {
                    changedData[key] = formData[key]; // Only capture the fields that were changed
                }
            });

            // console.log('Changed data: ', changedData);

            clientService.updateClient(customerId, changedData).then(res => {
                setSubmitState({
                    loading: false,
                    status: 'Profile updated sucessfully'
                })

                const logData = {
                    type: `Basic info Updated`,
                    userId: userPermissions.id,
                    createdAt: Date.now(),
                    customerId: customerId,
                    note: changedData
                };

                activitiesService.postActivity(logData);

                SweetAlert.fire({ title: 'Success', text: 'Profile updated sucessfully!', icon: 'success' }).then(() => {
                    // Refresh the page after the alert is closed
                    window.location.reload();
                });
            }).catch(err => {
                setSubmitState({
                    loading: false,
                    status: 'Error in updating Profile'
                })
                SweetAlert.fire({ title: 'Failed', text: err.response.data.message, icon: 'error' });
            });
            // alert('You submitted the form and stuff!');
        } else {
            errors.showMessages();
        }
    };

    const onAmlRegistrationHandler = async (e) => {
        e.preventDefault();
        data.operation = 'I';
        const apiResponse = await clientService.amlIntegration(data);

        if (apiResponse.ack === 'Success') {
            toast.success('Successfully Registered!');
        } else {
            toast.error("Failed to Register");
        }

    };

    const onAmlUpdateHandler = async (e) => {
        e.preventDefault();
        data.operation = 'U';
        const apiResponse = await clientService.amlIntegration(data);

        if (apiResponse.ack === 'Success') {
            toast.success('Successfully Registered!');
        } else {
            toast.error("Failed to Register");
        }

    };

    const onAgreementGenerateHandler = async (e) => {
        e.preventDefault();
        try {
            const apiResponse = clientService.generateAggrement(data);
            console.log('apiResponse: ', apiResponse);
        } catch (error) {
            console.log('generateAggrement error: ', error.message);
            toast.error('Unable to generate Agreement')
        }
    }

    const [tradeEnabled, setTradeEnabled] = useState(data.tradeDisabled);
    const [tradeDisabled, setTradeDisabled] = useState(!data.tradeDisabled);
    const [dbTradeDisable, setDbTradeDisable] = useState(data.tradeDisabled);

    const [screeningPass, setScreeningPass] = useState(data.screenFailed);
    const [screeningFailed, setScreeningFailed] = useState(!data.screenFailed);
    const [dbScreeningFailed, setDbScreeningFailed] = useState(data.screenFailed);

    const [compliancePass, setCompliancePass] = useState(data.complianceFailed);
    const [complianceFailed, setComplianceFailed] = useState(!data.complianceFailed);
    const [dbComplianceFailed, setDbComplianceFailed] = useState(data.complianceFailed);

    // const [fullName, setFullName] = useState(`${data.firstName} ${data.lastName ? data.lastName : ''}`);
    const [fullName, setFullName] = useState(`${data.firstName}`);

    const handleTradeEnableChange = () => {
        if (compliancePass === true && screeningPass === true) {
            setTradeEnabled(true);
            setChkTrade(true);
            setTradeDisabled(false); // Set tradeDisabled to opposite of tradeEnabled
            setDbTradeDisable(true);
        } else {
            setTradeEnabled(false);
            setTradeDisabled(true); // Set tradeDisabled to opposite of tradeEnabled
            setDbTradeDisable(false);
            toast.error('Please enable screening and complaince!');
        }
    };

    const handleTradeDisableChange = () => {
        setTradeDisabled(true);
        setTradeEnabled(false); // Set tradeEnabled to opposite of tradeDisabled
        setDbTradeDisable(false);
    };

    const handleScreenPassChange = () => {
        if (compliancePass === true) {
            setScreeningPass(true);
            setScreeningFailed(false); // Set tradeDisabled to opposite of tradeEnabled
            setDbScreeningFailed(true);
        } else {
            setScreeningPass(false);
            setScreeningFailed(true); // Set tradeDisabled to opposite of tradeEnabled
            setDbScreeningFailed(false);
            toast.error('Please enable complaince!');
        }
    };

    const handleScreenFailedChange = () => {
        setScreeningFailed(true);
        setScreeningPass(false); // Set tradeEnabled to opposite of tradeDisabled
        setDbScreeningFailed(false);
    };

    const handleCompliancePassChange = () => {
        setCompliancePass(true);
        setComplianceFailed(false); // Set tradeDisabled to opposite of tradeEnabled
        setDbComplianceFailed(true);

        const newDate = new Date();
        data.complianceDate = newDate;
        newDate.setFullYear(newDate.getFullYear() + 1); // Add 1 year to complianceCompleted date
        setPredioticDate(newDate.toISOString().split('T')[0]);
    };

    const handleComplianceFailedChange = () => {
        setComplianceFailed(true);
        setCompliancePass(false); // Set tradeEnabled to opposite of tradeDisabled
        setDbComplianceFailed(false);
    };

    const handleFullName = (e) => {
        setFullName(e.target.value);
        setValue('firstName', e.target.value);
    };

    const [predioticDate, setPredioticDate] = useState(null);

    useEffect(() => {
        if (data.complianceCompleted) {
            const complianceDate = new Date(data.complianceCompleted);
            const newDate = new Date(complianceDate);
            newDate.setFullYear(newDate.getFullYear() + 1); // Add 1 year to complianceCompleted date
            setPredioticDate(newDate.toISOString().split('T')[0]);
        }
    }, [data.complianceCompleted]);

    const aggrements = [
        { name: 'US Citizen?', value: 'usCitizen' },
        { name: 'Politically Exposed-Person?', value: 'politicallyExposed' },
        { name: 'Accepted Terms & Conditions', value: 'acceptedTerms' },
        { name: 'Accepted Risk Disclosure?', value: 'acceptedRisk' },
        { name: 'Accepted Privacy Policy?', value: 'acceptedPrivacy' },
        { name: 'Accepted Order Execution Policy?', value: 'acceptedExecutionPolicy' },
        { name: 'Accepted Conflicts Of Interest Policy?', value: 'acceptedConflicts' },
        { name: 'Accepted electronic trading terms?', value: 'acceptTradingTerms' }
    ];
    // { name: 'Signed Agreement?', value: 'acceptedConflicts' },
    // { name: 'Are you resident of any other countries?', value: 'otherCountryResidence' },
    // { name: 'Are you a national of any other countries?', value: 'otherCountryNational' },
    // { name: 'Will this account be traded by anyone other than you?', value: 'accountTradedByOthers' },
    //{name: 'Screening pass?', value: 'screeningPass'},
    //{name: 'Screening Failed?', value: 'screeningFailed'},

    // Define the formatDate function
    const formatDate = (date) => {
        // Create a new Date object
        const formattedDate = new Date(date);

        // Get the year, month, and day from the date object
        const year = formattedDate.getFullYear();
        const month = ('0' + (formattedDate.getMonth() + 1)).slice(-2);
        const day = ('0' + formattedDate.getDate()).slice(-2);

        // Format the date and time as 'YYYY-MM-DD 4:00:00'
        return `${year}-${month}-${day} 4:00:00`;
    };

    // Define a function to preprocess the DOB value
    const preprocessDob = (dob) => {
        if (!dob) return ''; // Return empty string if DOB is not provided

        // Parse the DOB string to create a Date object
        const dobDate = new Date(dob);

        // Check if DOB is valid
        if (!isNaN(dobDate.getTime())) {
            // If DOB is valid, format it to the required format
            return formatDate(dobDate);
        } else {
            // If DOB is invalid, return default formatted date
            return '1970-01-01 4:00:00';
        }
    };

    // useEffect to preprocess the DOB value when it changes
    useEffect(() => {
        // Preprocess the DOB value
        const processedDob = preprocessDob(data.dob);

        // Update the state with the processed DOB value
        setFormattedDob(processedDob);
    }, [data.dob]); // Trigger effect when data.dob changes

    return (
        <Fragment>
            <Row>
                {/* <Col md="{getMenuPerm(permissions, 'clientDetails:tasks') === 1 ? 12 : 8 mb-3"> */}
                <Col md={getMenuPerm(permissions, 'clientDetails:tasks') == 1 ? 8 : 12} className="mb-3">

                    <Card>
                        {/*<CardHeader className='p-3'>
                    Basic Information Individual
  </CardHeader>*/}
                        <CardBody>
                            <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>

                                <Row>
                                    <Col md='12 mb-3 fs_20 fw_600'>
                                        Basic info
                                    </Col>
                                </Row>
                                <hr></hr>
                                <Row>
                                    <Col md='4' >
                                        <FInput
                                            errors={errors}
                                            label='Full Name'
                                            name='firstName'
                                            value={fullName}
                                            defaultValue={fullName}
                                            handleOnChange={handleFullName}
                                            //register = {register}
                                            //placeholder= 'Enter First Name'
                                            validation={{ required: true }}
                                        />
                                    </Col>
                                    {/* <Col md='4' >
                                <FInput
                                    errors={errors}
                                    label = 'Last Name'
                                    name = 'lastName'
                                    register = {register}
                                    //placeholder= 'Enter Last Name'
                                />
                            </Col> */}
                                    <Col md='4' >
                                        <FUserSelect
                                            control={control}
                                            errors={errors}
                                            label='Account Owner'
                                            name='agentId'
                                            validation={{ required: true }}
                                            setValue={setValue}
                                            value={data.agentId}
                                            defaultOptions={data.Agent ? [data.Agent] : []}
                                        // isClient={true}
                                        />
                                    </Col>
                                    {getMenuPerm(userPermissions.isHidden, 'entity:hide') != 1 &&
                                        <Col md='4' >
                                            <FSelect
                                                control={control}
                                                errors={errors}
                                                label='Entity'
                                                name='entity'
                                                register={register}
                                                //placeholder= 'Select Client Type'
                                                options={[
                                                    { name: 'GODO Financial', value: 'FSA' },
                                                    { name: 'GODO', value: 'FSC' },
                                                    { name: 'GODO Introduction', value: 'SCA' },
                                                    { name: 'GODO Morocco', value: 'FSA_MA' },
                                                    { name: 'GODO Abbado', value: 'FSA_CA' }
                                                ]}
                                                disabled={getMenuPerm(permissions, 'entity:View') === 1 && true}
                                                value={data.entity || ''}
                                                validation={{ required: false }}
                                                setValue={setValue}
                                            />
                                        </Col>
                                    }
                                    <Col md='4' >
                                        <FSelect
                                            control={control}
                                            errors={errors}
                                            label='Nationality'
                                            name='nationality'
                                            register={register}
                                            //placeholder= 'Enter Nationality'
                                            validation={{ required: false }}
                                            value={data.nationality || ''}
                                            options={countriesStates.map(obj => {
                                                return {
                                                    name: obj.name,
                                                    value: obj.name
                                                }
                                            })}
                                            setValue={setValue}
                                        />

                                    </Col>
                                    <Col md='4' >
                                        <FSelect
                                            control={control}
                                            errors={errors}
                                            label='Country of Residence'
                                            name='country'
                                            register={register}
                                            //placeholder= 'Enter Country of Residence'
                                            validation={{ required: false }}
                                            options={countriesStates.map(obj => {
                                                return {
                                                    name: obj.name,
                                                    value: obj.name
                                                }
                                            })}
                                            setValue={setValue}
                                        />
                                    </Col>
                                    {getMenuPerm(permissions, 'clientDetails:email') === 1 &&
                                        <Col md='4' >
                                            <FInput
                                                errors={errors}
                                                label='Email'
                                                name='email'
                                                register={register}
                                                //placeholder= 'Enter Email Address'
                                                validation={{ required: true }}
                                                disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                            />
                                        </Col>
                                    }
                                    {getMenuPerm(permissions, 'clientDetails:phone') === 1 &&
                                        <Col md='4' >
                                            <FInput
                                                errors={errors}
                                                label='Phone'
                                                name='phone'
                                                register={register}
                                                disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                                //placeholder= 'Enter Phone Number'
                                                validation={{ required: false }}
                                            />
                                        </Col>
                                    }
                                    <Col md='4' style={{ display: 'none' }} >
                                        <FInput
                                            errors={errors}
                                            label='Language'
                                            name='language'
                                            register={register}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        //placeholder= 'Langauge'
                                        />
                                    </Col>
                                    <Col md='4' >
                                        <FSelect
                                            control={control}
                                            errors={errors}
                                            label='Account Stage'
                                            name='accountStage'
                                            register={register}
                                            //placeholder= 'Select Account Stage'
                                            options={[
                                                { name: 'Inactive', value: 'Inactive' },
                                                { name: 'Funded', value: 'Funded' },
                                                { name: 'Traded', value: 'Traded' },
                                                { name: 'Dormant', value: 'Dormant' },
                                                { name: 'Frozen', value: 'Frozen' },
                                                { name: 'Closed', value: 'Closed' },
                                                { name: 'Blacklisted', value: 'Blacklisted' },
                                            ]}

                                            validation={{ required: false }}
                                            value={
                                                data.accountStage ? data.accountStage :
                                                    data.startTrading ? 'Traded' :
                                                        data.isFunded ? 'Funded' :
                                                            'Inactive'
                                            }
                                            setValue={setValue}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4' style={{ display: 'none' }} >
                                        <FDateTime
                                            errors={errors}
                                            label='Created Time'
                                            name='createdAt'
                                            register={register}
                                            type="date"
                                            selectedValue={data.createdAt}
                                            disabled
                                        />
                                    </Col>
                                    <Col md='4' style={{ display: 'none' }} >
                                        <FDateTime
                                            errors={errors}
                                            label='Modified Time'
                                            name='updatedAt'
                                            register={register}
                                            type="date"
                                            selectedValue={data.updatedAt}
                                            disabled
                                        />
                                    </Col>
                                    <Col md='4' >
                                        <FUserSelect
                                            control={control}
                                            errors={errors}
                                            label='Retention Owner'
                                            name='retention'
                                            validation={{ required: false }}
                                            setValue={setValue}
                                            value={data.retention || ''}
                                            defaultOptions={data.Retention ? [data.Retention] : []}

                                        // isClient={true}
                                        />
                                    </Col>
                                    <Col md='4'>
                                        <FGroupSelect
                                            control={control}
                                            errors={errors}
                                            label='Select Group'
                                            name='groupId'
                                            validation={{ required: false }}
                                            setValue={setValue}
                                            value={data.groupId}
                                            defaultOptions={data.Group ? [data.Group] : ''}
                                        />
                                    </Col>
                                    <Col md='4' >
                                        <FSelect
                                            control={control}
                                            errors={errors}
                                            label='Account Type'
                                            name='clientType'
                                            register={register}
                                            //placeholder= 'Select Client Type'
                                            options={[
                                                { name: 'Individual', value: 'Individual' },
                                                { name: 'Corporate', value: 'Corporate' },
                                            ]}

                                            validation={{ required: false }}
                                            value={data.clientType}
                                            setValue={setValue}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4' style={{ display: 'flex', alignItems: 'center', marginBottom: '0' }} >
                                        <FCheckbox
                                            label='Email Opt Out?'
                                            name='emailVerified'
                                            setValue={setValue}
                                            value={data.emailVerified || ''}
                                        />
                                    </Col>
                                    <Col md='4' >
                                        <FInput
                                            errors={errors}
                                            label='Referred by:'
                                            name='referrar'
                                            register={register}
                                            disabled={true}
                                        //placeholder= 'Langauge'
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none', alignItems: 'center' }} >
                                        <FCheckbox
                                            label='Receive Newsletters?'
                                            name='newsLetters'
                                            setValue={setValue}
                                            value={data.newsLetters || ''}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md='12 my-3 fs_20 fw_600'>
                                        Personal and Financial info
                                    </Col>
                                </Row>
                                <hr></hr>
                                <Row>
                                    <Col md='4' >
                                        <FInput
                                            errors={errors}
                                            label='Current/ Permanent Address'
                                            name='address'
                                            register={register}
                                            //placeholder= 'Enter Address'
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                            validation={{ required: false }}
                                        />
                                    </Col>
                                    <Col md='4'>
                                        <FDateTime
                                            errors={errors}
                                            label='Date of Birth'
                                            name='dob'
                                            register={register}
                                            type='date'
                                            setValue={setValue}
                                            selectedValue={formattedDob}
                                        />
                                    </Col>
                                    <Col md='4' >
                                        <FSelect
                                            control={control}
                                            errors={errors}
                                            label='ID Type'
                                            name='idType'
                                            register={register}
                                            //placeholder= 'Select Account Status'
                                            options={[
                                                { name: 'EID-EMIRATES ID', value: 'EID-EMIRATES ID' },
                                                { name: 'PP-PASSPORT', value: 'PP-PASSPORT' },
                                                { name: 'NID-NATIONAL ID', value: 'NID-NATIONAL ID' },
                                                { name: 'DL-DRIVING LICENSE', value: 'DL-DRIVING LICENSE' },
                                                { name: 'RESIDENT PERMIT CARD', value: 'RESIDENT PERMIT CARD' },
                                            ]}

                                            validation={{ required: false }}
                                            //value={data.accountStatus ? data.accountStatus : 'New'}
                                            //value={data.accountStatus ? data.accountStatus : ''}
                                            setValue={setValue}
                                        />
                                    </Col>
                                    <Col md='4' >
                                        <FInput
                                            errors={errors}
                                            label='ID Number'
                                            name='passportIdNo'
                                            register={register}
                                            //placeholder= 'Enter City'
                                            validation={{ required: false }}
                                        />
                                    </Col>
                                    <Col md='4' style={{ display: 'none' }} >
                                        <FInput
                                            errors={errors}
                                            label='City'
                                            name='city'
                                            register={register}
                                            //placeholder= 'Enter City'
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                            validation={{ required: false }}
                                        />
                                    </Col>
                                    <Col md='4' style={{ display: 'none' }} >
                                        <FSelect
                                            control={control}
                                            errors={errors}
                                            label='Education Level'
                                            name='educationLevel'
                                            register={register}
                                            options={[
                                                { name: 'No Formal Education', value: 'No formal education' },
                                                { name: 'Primary Education', value: 'Primary education' },
                                                { name: 'Secondary Education or High School', value: 'Secondary education' },
                                                { name: 'GED', value: 'GED' },
                                                { name: 'Vocational Qualification', value: 'Vocational qualification' },
                                                { name: "Bachelor's Degree", value: "Bachelor's degree" },
                                                { name: "Master's degree", value: "Master's degree" },
                                                { name: 'Doctorate or Higher', value: 'Doctorate or higher' }
                                            ]}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                            validation={{ required: false }}
                                            value={data.educationLevel || ''}
                                            setValue={setValue}
                                        />
                                    </Col>
                                    <Col md='4' style={{ display: 'none' }} >
                                        <FSelect
                                            control={control}
                                            errors={errors}
                                            label='Employment Status'
                                            name='employmentStatus'
                                            register={register}
                                            options={[
                                                { name: 'Salaried employee', value: 'Salaried employee' },
                                                { name: 'Self-employee', value: 'Self-employee' },
                                                { name: 'Entrepreneur', value: 'Entrepreneur' },
                                                { name: 'Unemployed', value: 'Unemployed' },
                                                { name: 'Retired', value: 'Retired' },
                                                { name: 'Student', value: 'Student' }
                                            ]}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                            validation={{ required: false }}
                                            value={data.employmentStatus || ''}
                                            setValue={setValue}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <FInput
                                            errors={errors}
                                            label='Profession'
                                            name='profession'
                                            register={register}
                                            //placeholder= 'Enter Profession'
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                            validation={{ required: false }}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <FInput
                                            errors={errors}
                                            label='Employer Name'
                                            name='ownCompany'
                                            register={register}
                                            //placeholder= 'Enter Name of Own Company'
                                            validation={{ required: false }}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <FInput
                                            errors={errors}
                                            label='Annual Deposit'
                                            name='annualIncome'
                                            register={register}
                                            //placeholder= 'Enter Name of Own Company'
                                            validation={{ required: false }}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        {/* <FInput
                                            errors={errors}
                                            label='Source Of Funds'
                                            name='sourceOfFunds'
                                            register={register}
                                            //placeholder= 'Enter Source Of Funds'
                                            validation={{ required: false }}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        /> */}
                                        <FSelect
                                            control={control}
                                            errors={errors}
                                            label='Source Of Funds'
                                            name='sourceOfFunds'
                                            register={register}
                                            //placeholder= 'Select Account Status'
                                            options={[
                                                { name: 'Salaried', value: 'Salaried' },
                                                { name: 'Self-Employed', value: 'Self-Employed' },
                                                { name: 'Freelancer', value: 'Freelancer' },
                                                { name: 'Student', value: 'Student' },
                                                { name: 'Management', value: 'Management' },
                                                { name: 'Unemployed', value: 'Unemployed' },
                                            ]}

                                            validation={{ required: false }}
                                            //value={data.accountStatus ? data.accountStatus : 'New'}
                                            //value={data.accountStatus ? data.accountStatus : ''}
                                            setValue={setValue}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        {/* <FInput
                                            errors={errors}
                                            label='Net Worth'
                                            name='netWorth'
                                            register={register}
                                            //placeholder= 'Enter Net Worth'
                                            validation={{ required: false }}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        /> */}
                                        <FSelect
                                            control={control}
                                            errors={errors}
                                            label='Net Worth'
                                            name='netWorth'
                                            register={register}
                                            //placeholder= 'Select Account Status'
                                            options={[
                                                { name: 'USD 0 - USD 50,000', value: 'USD 0 - USD 50,000' },
                                                { name: 'USD 50,000 - USD 100,000', value: 'USD 50,000 - USD 100,000' },
                                                { name: "USD 100,000 - USD 200,0000", value: "USD 100,000 - USD 200,0000" },
                                                { name: "USD 200,000 - USD 500,000", value: "USD 200,000 - USD 500,000" },
                                                { name: "USD 500,000 - USD 1,000,000", value: "USD 500,000 - USD 1,000,000" },
                                                { name: "USD 1,000,000 - USD 2,000,000", value: "USD 1,000,000 - USD 2,000,000" },
                                                { name: "USD 2,000,000 - USD 5,000,000", value: "USD 2,000,000 - USD 5,000,000" },
                                                { name: "USD 5,000,000 - USD 10,000,000", value: "USD 5,000,000 - USD 10,000,000" },
                                                { name: "USD 10,000,000 +", value: "USD 10,000,000 +" }
                                            ]}
                                            validation={{ required: false }}
                                            //value={data.accountStatus ? data.accountStatus : 'New'}
                                            //value={data.accountStatus ? data.accountStatus : ''}
                                            setValue={setValue}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <FDateTime
                                            errors={errors}
                                            label='Last Login'
                                            name='lastLogin'
                                            register={register}
                                            //placeholder= 'lastLogin'
                                            type="date"
                                            selectedValue={data.lastLogin}
                                            // value={data.lastLogin}
                                            disabled
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FSelect
                                            control={control}
                                            errors={errors}
                                            label='Annual Income'
                                            name='annualIncome'
                                            register={register}
                                            options={[
                                                { name: "USD 0 - USD 50,000", value: "USD 0 - USD 50,000" },
                                                { name: "USD 50,000 - USD 100,000", value: "USD 50,000 - USD 100,000" },
                                                { name: "USD 100,000 - USD 200,0000", value: "USD 100,000 - USD 200,0000" },
                                                { name: "USD 200,000 - USD 500,000", value: "USD 200,000 - USD 500,000" },
                                                { name: "USD 500,000 - USD 1,000,000", value: "USD 500,000 - USD 1,000,000" },
                                                { name: "USD 1,000,000 +", value: "USD 1,000,000 +" }
                                            ]}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                            validation={{ required: false }}
                                            setValue={setValue}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <FInput
                                            errors={errors}
                                            label='Purpose of Investment'
                                            name='investmentPurpose'
                                            register={register}
                                            //placeholder= 'Enter Purpose of Investment'
                                            validation={{ required: false }}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <FInput
                                            errors={errors}
                                            label='IP'
                                            name='ip'
                                            register={register}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        //placeholder= 'IP'
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <FInput
                                            errors={errors}
                                            label='Country of Tax Residency'
                                            name='taxResidency'
                                            register={register}
                                            //placeholder= 'Enter Country of Tax Residency'
                                            validation={{ required: false }}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FSelect
                                            control={control}
                                            errors={errors}
                                            label='Preferable mode of Payment'
                                            name='modeOfPayment'
                                            register={register}
                                            //placeholder= 'Select Account Status'
                                            options={[
                                                { name: 'Bank Wire', value: 'Bank Wire' },
                                                { name: 'Online', value: 'Online' },
                                                data.entity === 'FSA' && { name: 'Crypto', value: 'Crypto' }
                                            ]}

                                            validation={{ required: false }}
                                            //value={data.accountStatus ? data.accountStatus : 'New'}
                                            //value={data.accountStatus ? data.accountStatus : ''}
                                            setValue={setValue}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FInput
                                            errors={errors}
                                            label='US-TIN ( If )'
                                            name='taxIdentificationNumber'
                                            register={register}
                                            //placeholder= 'Enter Tax ID'
                                            validation={{ required: false }}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col md='12 mb-3 fs_20 fw_600'>
                                        Trading Info
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col md='4 mb-3' >
                                        <FSelect
                                            control={control}
                                            errors={errors}
                                            label='Experienced trading before?'
                                            name='experiencedTrading'
                                            options={[
                                                { name: 'Yes', value: true },
                                                { name: 'No', value: false },
                                            ]}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FSelect
                                            control={control}
                                            errors={errors}
                                            label='What products you have traded in?'
                                            name='sharesFrequency'
                                            register={register}
                                            options={[
                                                { name: 'Shares, Bonds, Equities, ETFs', value: 'Shares, Bonds, Equities, ETFs' },
                                                { name: 'Derivatives (Future, Options, Swaps)', value: 'Derivatives (Future, Options, Swaps)' },
                                                { name: 'Forex or Rolling Spot (FX)', value: 'Forex or Rolling Spot (FX)' },
                                                { name: 'CFDs', value: 'CFDs' }
                                            ]}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                            validation={{ required: false }}
                                            setValue={setValue}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FSelect
                                            control={control}
                                            errors={errors}
                                            label='Frequency'
                                            name='cfdFrequency'
                                            register={register}
                                            options={[
                                                { name: 'Rarely: 1 to 25 Trades A Year', value: 'Rarely: 1 to 25 Trades A Year' },
                                                { name: 'Sometime: 25 to 100 Trades A Year', value: 'Sometime: 25 to 100 Trades A Year' },
                                                { name: 'Often: Over a 100 Trades A Year', value: 'Often: Over a 100 Trades A Year' },
                                            ]}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                            validation={{ required: false }}
                                            setValue={setValue}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FInput
                                            errors={errors}
                                            label='FTD Amount'
                                            name='ftDepositAmount'
                                            register={register}
                                            //placeholder= 'Enter Risk Level'
                                            validation={{ required: false }}
                                            disabled
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <Label className='d-block' for={'Net Deposits In USD'}>
                                            {'Net Deposits In USD'}
                                        </Label>
                                        <Input
                                            errors={errors}
                                            label='Net Deposits In USD'
                                            name='depositInUSD'
                                            register={register}
                                            //placeholder= 'Enter Risk Level'
                                            value={data.Transactions && data.Transactions[0] ? (data.Transactions[0].totalDeposits - data.Transactions[0].totalWithdrawals) : '0'}
                                            validation={{ required: false }}
                                            disabled
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FDateTime
                                            errors={errors}
                                            label='First Deposit Date'
                                            name='ftDepositTime'
                                            register={register}
                                            type="date"
                                            selectedValue={data.ftDepositTime}
                                            disabled
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <Label className='d-block' for={'Total Deposited In USD'}>
                                            {'Total Deposited In USD'}
                                        </Label>
                                        <Input
                                            errors={errors}
                                            label='Total Deposited In USD'
                                            name='depositInUSD'
                                            register={register}
                                            //placeholder= 'Enter Risk Level'
                                            value={data.Transactions && data.Transactions[0] ? data.Transactions[0].totalDeposits : '0'}
                                            validation={{ required: false }}
                                            disabled
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <Label className='d-block' for={'Total Withdrawn In USD'}>
                                            {'Total Withdrawn In USD'}
                                        </Label>
                                        <Input
                                            errors={errors}
                                            label='Total Withdrawn In USD'
                                            name='depositInUSD'
                                            register={register}
                                            //placeholder= 'Enter Risk Level'
                                            value={data.Transactions && data.Transactions[0] ? data.Transactions[0].totalWithdrawals : '0'}
                                            validation={{ required: false }}
                                            disabled
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FDateTime
                                            errors={errors}
                                            label='Last Deposit Date'
                                            name='lastDepositDate'
                                            register={register}
                                            type="date"
                                            selectedValue={data.Transactions && data.Transactions[0] && data.Transactions[0].lastDepositDate}
                                            disabled
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <FInput
                                            errors={errors}
                                            label='CFD Trading Frequency'
                                            name='cfdFrequency'
                                            register={register}
                                            //placeholder= 'Enter CFD Trading Frequency'
                                            validation={{ required: false }}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <FInput
                                            errors={errors}
                                            label='Forex Trading Frequency'
                                            name='forexFrequency'
                                            register={register}
                                            //placeholder= 'Enter Forex Trading Frequency'
                                            validation={{ required: false }}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FDateTime
                                            errors={errors}
                                            label='First  Traded Date'
                                            name='firstTradeDate'
                                            register={register}
                                            type="date"
                                            selectedValue={data.lastLogin}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <FInput
                                            errors={errors}
                                            label='Other Derivatives Frequency'
                                            name='otherDerivativesFrequency'
                                            register={register}
                                            //placeholder= 'Enter Other Derivatives Frequency'
                                            validation={{ required: false }}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <FInput
                                            errors={errors}
                                            label='Shares/Bonds Frequency'
                                            name='sharesFrequency'
                                            register={register}
                                            //placeholder= 'Enter Shares/Bonds Frequency'
                                            validation={{ required: false }}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FDateTime
                                            errors={errors}
                                            label='Last Traded Date'
                                            name='lastTradeDate'
                                            register={register}
                                            type="date"
                                            selectedValue={data.lastLogin}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FSelect
                                            control={control}
                                            errors={errors}
                                            label='Beneficial Owner'
                                            name='accountTradedByOthers'
                                            register={register}
                                            setValue={setValue}
                                            validation={{ required: false }}
                                            options={[
                                                { name: 'SELF', value: true },
                                                { name: 'INDIVIDUAL', value: false },
                                            ]}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FSelect
                                            control={control}
                                            errors={errors}
                                            label='Third Party Authorization'
                                            name='acceptedClientAgreement'
                                            options={[
                                                { name: 'Yes', value: true },
                                                { name: 'No', value: false },
                                            ]}
                                        />
                                    </Col>
                                    <Col md={12} style={data.entity ? { display: 'block' } : { display: 'none' }}>
                                        <div className='d-flex align-items-start fs-4'>
                                            <input
                                                type="checkbox"
                                                name="agreement"
                                                style={{ marginTop: '7px' }}
                                                checked={true}
                                            />
                                            <p className="mb-0 ms-2">
                                                {
                                                    data.entity === 'FSA' ? "I hereby signed my agreement with GODO LTD and agree that my trading account is subject to the regulations under Financial Services Authority (FSA), Saint Vincent." :
                                                        data.entity === 'SCA' ? "I hereby signed my agreement with GODO LTD and agree that my trading account is subject to the regulations under Securities Commodities Authority (SCA), UAE." :
                                                            "I hereby signed my agreement with GODO LTD and agree that my trading account is subject to the regulations under Financial Services Commission (FSC), Mauritius."

                                                }
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col md='12 mb-3 fs_20 fw_600'>
                                        Compliance  Check
                                    </Col>
                                </Row>
                                <hr />
                                <Row>

                                    {aggrements.map((agg, index) =>
                                        // <Col md={index === aggrements.length - 1 ? '12' : '6'} mb='3' key={index}></Col>
                                        <Col md='6' mb='3' key={index}>
                                            <FCheckbox
                                                label={agg.name}
                                                name={agg.value}
                                                setValue={setValue}
                                                value={data[agg.value]}
                                            />
                                        </Col>
                                    )}
                                    <div className='mb-4'></div>
                                    <Col md='6'>
                                        <Label className='d-block' for={'Trade Enabled?'}>
                                            <Input
                                                checked={tradeEnabled}
                                                onChange={handleTradeEnableChange}
                                                className='checkbox_animated'
                                                name={'tradeDisabled'}
                                                id={'Trade Enabled?'}
                                                type='checkbox'
                                            />
                                            {'Trade Enabled?'}
                                        </Label>
                                    </Col>
                                    <Col md='6'>
                                        <Label className='d-block' for={'Trade Disabled?'}>
                                            <Input
                                                checked={tradeDisabled}
                                                onChange={handleTradeDisableChange}
                                                className='checkbox_animated'
                                                name={'tradeDisabled'}
                                                id={'Trade Disabled?'}
                                                type='checkbox'
                                                disabled={tradeEnabled}
                                            />
                                            {'Trade Disabled?'}
                                        </Label>
                                    </Col>
                                    <Col md='6'>
                                        <Label className='d-block' for={'Screening Pass?'}>
                                            <Input
                                                checked={screeningPass}
                                                onChange={handleScreenPassChange}
                                                className='checkbox_animated'
                                                name={'screenFailed'}
                                                id={'Screening Pass?'}
                                                type='checkbox'
                                            />
                                            {'Screening Pass?'}
                                        </Label>
                                    </Col>
                                    <Col md='6'>
                                        <Label className='d-block' for={'Screening Failed?'}>
                                            <Input
                                                checked={screeningFailed}
                                                onChange={handleScreenFailedChange}
                                                className='checkbox_animated'
                                                name={'screenFailed'}
                                                id={'Screening Failed?'}
                                                type='checkbox'
                                                disabled={screeningPass}
                                            />
                                            {'Screening Failed?'}
                                        </Label>
                                    </Col>
                                    <Col md='6 mb-3'>
                                        <Label className='d-block' for={'Compliance Pass?'}>
                                            <Input
                                                checked={compliancePass}
                                                onChange={handleCompliancePassChange}
                                                className='checkbox_animated'
                                                name={'complianceFailed'}
                                                id={'Compliance Pass?'}
                                                type='checkbox'
                                            />
                                            {'Compliance Pass?'}
                                        </Label>
                                    </Col>
                                    <Col md='6 mb-3'>
                                        <Label className='d-block' for={'Compliance Failed?'}>
                                            <Input
                                                checked={complianceFailed}
                                                onChange={handleComplianceFailedChange}
                                                className='checkbox_animated'
                                                name={'complianceFailed'}
                                                id={'Compliance Failed?'}
                                                type='checkbox'
                                                disabled={compliancePass}
                                            />
                                            {'Compliance Failed?'}
                                        </Label>
                                    </Col>
                                    <Col md='4' >
                                        <FSelect
                                            control={control}
                                            errors={errors}
                                            label='Account Status'
                                            name='accountStatus'
                                            register={register}
                                            //placeholder= 'Select Account Status'
                                            options={[
                                                { name: 'New', value: 'New' },
                                                { name: 'Pending', value: 'Pending' },
                                                { name: 'Rejected', value: 'Rejected' },
                                                { name: 'Approved', value: 'Approved' },
                                                { name: 'Active', value: 'Active' },
                                                { name: 'Frozen', value: 'Frozen' },
                                                { name: 'Closed', value: 'Closed ' },
                                                { name: 'Blacklisted', value: 'Blacklisted' },
                                                { name: 'Demo', value: 'Demo' },
                                            ]}

                                            validation={{ required: false }}
                                            value={data.accountStatus ? data.accountStatus : 'New'}
                                            //value={data.accountStatus ? data.accountStatus : ''}
                                            setValue={setValue}
                                        />
                                    </Col>
                                    <Col md='4' >
                                        <FSelect
                                            control={control}
                                            errors={errors}
                                            label='Approval Stage'
                                            name='approvalStage'
                                            register={register}
                                            placeholder={data.approvalStage}
                                            options={[
                                                { name: 'New Application', value: 'New Application' },
                                                { name: 'Documents Approved', value: 'Documents Approved' },
                                                { name: 'Pending Documents', value: 'Pending Documents' },
                                                { name: 'KYC / CDD Approved', value: 'KYC / CDD Approved' },
                                                { name: 'Trade Enabled/Active', value: 'Trade Enabled/Active' }
                                            ]}
                                            value={data.approvalStage || 'New Application'}
                                            setValue={setValue}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <FInput
                                            errors={errors}
                                            label='Risk Level'
                                            name='riskLevel'
                                            register={register}
                                            //placeholder= 'Enter Risk Level'
                                            validation={{ required: false }}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' style={{ display: 'none' }} >
                                        <FDateTime
                                            errors={errors}
                                            label='Compliance Completed'
                                            name='complianceCompleted'
                                            register={register}
                                            type="date"
                                            setValue={setValue}
                                            selectedValue={data.complianceCompleted}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />

                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FDateTime
                                            errors={errors}
                                            label='Periodic Check Date'
                                            name='predioticDate'
                                            register={register}
                                            type="date"
                                            selectedValue={predioticDate}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                                <br />
                                                <Row>
                                                  <Col md="12 mb-3 fs_20 fw_600">Lead Info</Col>
                                                </Row>
                                                <hr></hr>
                                                <Row>
                                                  <Col md="4 mb-3">
                                                    <FSelect
                                                      control={control}
                                                      errors={errors}
                                                      label="Lead Source"
                                                      name="source"
                                              
                                                      register={register}
                                                      value={getValues("source") || data.source || ""}
                                                      options={[
                                                        ...(data.source &&
                                                        ![
                                                          "MARKETING",
                                                          "MARKETING-A",
                                                          "MARKETING-I",
                                                          "MARKETING-W",
                                                        ].includes(data.source)
                                                          ? [{ name: data.source, value: data.source }]
                                                          : []),
                                                        { name: "MARKETING", value: "MARKETING" },
                                                        { name: "MARKETING-A", value: "MARKETING-A" },
                                                        { name: "MARKETING-I", value: "MARKETING-I" },
                                                        { name: "MARKETING-W", value: "MARKETING-W" },
                                                      ]}
                                                      setValue={setValue}
                                                    />
                                                  </Col>
                                                </Row>
                                                <br />
                                <Row>
                                    <Col md='12 mb-3 fs_20 fw_600'>
                                        Marketing info
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col md='4 mb-3' >
                                        <FInput
                                            errors={errors}
                                            label='UTM Campaign'
                                            //placeholder= 'UTM Campaign'
                                            name='utmCampaign'
                                            register={register}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FInput
                                            errors={errors}
                                            label='UTM Source'
                                            //placeholder= 'UTM Source'
                                            name='utmSource'
                                            register={register}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FInput
                                            errors={errors}
                                            label='UTM Medium'
                                            //placeholder= 'UTM Medium'
                                            name='utmMedium'
                                            register={register}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FInput
                                            errors={errors}
                                            label='UTM Term'
                                            //placeholder= 'UTM Term'
                                            name='utmTerm'
                                            register={register}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FInput
                                            errors={errors}
                                            label='UTM Content'
                                            //placeholder= 'UTM Content'
                                            name='utmContent'
                                            register={register}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3' >
                                        <FInput
                                            errors={errors}
                                            label='UTM Category'
                                            //placeholder= 'UTM Category'
                                            name='utmCategory'
                                            register={register}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3'>
                                        <FInput
                                            errors={errors}
                                            label='Do you have experience as an IB or in financial trading ?'
                                            name='expAsIB'
                                            register={register}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />

                                    </Col>
                                    <Col md='4 mb-3'>
                                        <FInput
                                            errors={errors}
                                            label='How do you plan on promoting GoDoCM to your network ?'
                                            name='promotionplans'
                                            register={register}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                    <Col md='4 mb-3'>
                                        <FInput
                                            errors={errors}
                                            label='How many clients do you have in your network or database ?'
                                            name='noofClients'
                                            register={register}
                                            disabled={getMenuPerm(permissions, 'clients:disable') === 1 && true}
                                        />
                                    </Col>
                                </Row>



                                {getMenuPerm(permissions, 'clients:update') === 1 &&
                                    <Btn disabled={submitState.loading} attrBtn={{ color: 'primary' }} >{'Update'}</Btn>
                                }
                            </Form>
                            {getMenuPerm(permissions, 'aml:view') === 1 &&
                                <Row>
                                    <Col md='12' className='d-flex align-items-center gap-2 mt-5'>
                                        <div onClick={onAmlRegistrationHandler}>
                                            <Btn
                                                attrBtn={{ color: "primary" }}
                                                name='add'
                                            >
                                                AML Registration
                                            </Btn>
                                        </div>
                                        <div onClick={onAmlUpdateHandler}>
                                            <Btn
                                                attrBtn={{ color: "primary" }}
                                            >
                                                AML Update
                                            </Btn>
                                        </div>
                                        <div onClick={onAgreementGenerateHandler}>
                                            <Btn
                                                attrBtn={{ color: "primary" }}
                                                name='add'
                                            >
                                                Generate Agreement
                                            </Btn>
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </CardBody>
                    </Card>
                </Col>
                {getMenuPerm(permissions, 'clientDetails:tasks') == 1 &&
                    <Col md="4 mb-3">

                        <Card style={{ overflow: 'visible', height: '400px', overflowY: 'scroll' }}>
                            <CardBody style={{ padding: '30px' }}>
                                <Row>
                                    <Col>
                                        <Notes customerId={customerId} setFlagToTrue={setFlagToTrue} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <ListGroup>
                                            {/* map over and print items */}
                                            {list && list !== undefined && list.map((item) => (
                                                <div key={item.id}>
                                                    <ListGroupItem
                                                        variant="dark"
                                                        action
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            border: 'none',
                                                            borderBottom: '1px solid rgba(0,0,0,.125)',
                                                        }}
                                                    >
                                                        {item.value}
                                                    </ListGroupItem>
                                                </div>
                                            ))}
                                        </ListGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        {getMenuPerm(permissions, 'clients:GroupUpdate') === 1 && <Card style={{ overflow: 'visible', height: '400px', overflowY: 'scroll' }}>
                            <CardBody style={{ padding: '30px' }}>
                                <Row>
                                    <Col>
                                        <GroupNotes customerId={customerId} setFlagToTrue={setFlagToTrue} />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>}
                    </Col>
                }
            </Row>
        </Fragment>
    );
};
export default BasicInformation;
