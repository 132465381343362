import api from './api';

// Fetch all attendance records

export const fetchAttendanceRecords = async (query = '') => {
  const res = await api.get(`/v1/crm/attendance${query}`);
  return res.data;
};

// Fetch only current month's attendance records
export const fetchCurrentMonthAttendance = async (page = 1, limit = 10000) => {
    try {
      console.log(`📡 Fetching Current Month Attendance: Page ${page}, Limit ${limit}`);
      
      const response = await api.get(`/v1/crm/attendance/current-month`, {
        params: { page, limit }
      });
  
      // ✅ Ensure response has `rows`
      if (!response || !response.data || !Array.isArray(response.data)) {
        console.warn("⚠️ Attendance API returned an invalid response:", response);
        return { rows: [] }; // Return empty array inside an object
      }
  
      console.log("✅ Attendance API Response:", response.data);
      return { rows: response.data }; // ✅ Return `rows` inside an object
    } catch (error) {
      console.error("❌ Error fetching current month attendance:", error);
      return { rows: [] }; // Return empty object if API fails
    }
  };
  // Fetch only today's attendance records
  export const fetchTodaysAttendance = async (page = 1, limit = 1000) => {
    try {
        console.log(`📡 Fetching Today's Attendance: Page ${page}, Limit ${limit}`);
        
        const response = await api.get(`/v1/crm/attendance/today`, {
            params: { page, limit }
        });
  
        console.log("📌 Full API Response:", response);
  
        // ✅ Check if response structure contains `data` correctly
        if (!response || !response.data) {
            console.warn("⚠️ Invalid API response: No data found", response);
            return { rows: [] };
        }
  
        console.log("✅ Extracted API Data:", response.data);
  
        // 🛠 Try different response structures to find the correct one:
        return {
            rows: response.data.rows || response.data.data || [], 
            total: response.data.total || 0
        };
    } catch (error) {
        console.error("❌ Error fetching today's attendance:", error);
        return { rows: [] };
    }
  };
  
// Delete an attendance record
export const deleteAttendance = (id) => {
    return api.delete(`/v1/crm/attendance/${id}`);
};
