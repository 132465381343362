import React, { useEffect, useRef, useState } from "react";
import OrgChart from "@dabeng/react-orgchart";
import { toast } from "react-toastify";
import {
  UsersPersonalDetail,
  getDocumentSas,
} from "Services/usersPersonalDetail.service";
import "./OrgChart.css"; // Make sure this CSS file is created

// Get presigned image URL from S3
const getUrl = async (personId) => {
  try {
    const res = await getDocumentSas(personId);
    return res?.url || null;
  } catch (err) {
    console.error(`Error fetching image for ${personId}:`, err);
    return null;
  }
};

// Attach image URLs to users
const attachImages = async (users) => {
  return Promise.all(
    users.map(async (u) => {
      const url = await getUrl(u.id);
      return { ...u, imageUrl: url || "" };
    })
  );
};

// Build the org tree
const buildTree = (users) => {
  const byId = Object.fromEntries(users.map((u) => [u.userid, u]));
  const activeUsers = users.filter((u) => u.status === "Active");

  const companyNode = {
    id: "company",
    name: "GODO",
    position: "Organization",
    children: [],
    collapsed: false,
  };

  const user69 = byId["69"];
  const user70 = byId["70"];

  const node69 = {
    id: user69.userid,
    name: user69.name,
    position: user69.job_title,
    image: user69.imageUrl,
    children: [],
    collapsed: false,
  };

  const node70 = {
    id: user70.userid,
    name: user70.name,
    position: user70.job_title,
    image: user70.imageUrl,
    children: [],
    collapsed: false,
  };

  const departments = [...new Set(activeUsers.map((u) => u.department).filter(Boolean))];

  const excludedDepartments = ["SALES", "MANAGEMENT"];

  departments.forEach((dept) => {
    if (excludedDepartments.includes(dept.toUpperCase())) return;
  
    const deptUsers = activeUsers.filter((u) => u.department === dept);
    if (deptUsers.length === 0) return;
  
    const head = deptUsers.find((u) => u.direct_managerId === "69");
  
    let headNode;
  
    if (head) {
      const others = deptUsers.filter((u) => u.direct_managerId === head.userid);
  
      headNode = {
        id: head.userid,
        name: head.name,
        position: head.job_title,
        image: head.imageUrl,
        children: others.map((emp) => ({
          id: emp.userid,
          name: emp.name,
          position: emp.job_title,
          image: emp.imageUrl,
          children: [],
          collapsed: true,
        })),
        collapsed: true,
      };
    } else {
      // Placeholder node if no head
      headNode = {
        id: `placeholder-${dept}`,
        name: `${dept} Team`,
        position: "No Team Lead",
        image: "",
        children: deptUsers.map((emp) => ({
          id: emp.userid,
          name: emp.name,
          position: emp.job_title,
          image: emp.imageUrl,
          children: [],
          collapsed: true,
        })),
        collapsed: true,
      };
    }
  
    const deptNode = {
      id: `dept-${dept}`,
      name: dept,
      position: "Department",
      children: [headNode],
      collapsed: true,
    };
  
    node69.children.push(deptNode);
  });
  
  const buildSubtree = (managerId) => {
    const subs = activeUsers.filter((u) => u.direct_managerId === managerId);
    return subs.map((emp) => ({
      id: emp.userid,
      name: emp.name,
      position: emp.job_title,
      image: emp.imageUrl,
      children: buildSubtree(emp.userid),
      collapsed: true,
    }));
  };

  node70.children = buildSubtree("70");

  companyNode.children.push(node69, node70);
  return companyNode;
};

// Custom node component
const CustomNode = ({ nodeData }) => (
  <div className="org-node">
    <img
      src={nodeData.image || "https://www.godocm.com/wp-content/uploads/2023/12/GoDo-Logo-2.png"}
      alt={nodeData.name}
    />
    <h4>{nodeData.name}</h4>
    <p>{nodeData.position}</p>
  </div>
);

const OrgChartView = () => {
  const [orgData, setOrgData] = useState(null);
  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  const zoomIn = () => {
    if (chartInstance) {
      const scale = chartInstance.getScale();
      chartInstance.setScale(scale * 1.2);
    }
  };

  const zoomOut = () => {
    if (chartInstance) {
      const scale = chartInstance.getScale();
      chartInstance.setScale(scale * 0.8);
    }
  };

  const resetZoom = () => {
    if (chartInstance) {
      chartInstance.setScale(1);
    }
  };

  useEffect(() => {
    UsersPersonalDetail({
      params: { status: "Active", t: Date.now() },
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
      },
    })
      .then((res) => {
        const users = res;
        const activeUsers = users.filter((u) => u.status === "Active");
        if (!activeUsers.length) {
          toast.error("No active users found.");
          return;
        }
        return attachImages(activeUsers);
      })
      .then((usersWithImages) => {
        if (!usersWithImages) return;
        const tree = buildTree(usersWithImages);
        setOrgData(tree);
      })
      .catch((err) => {
        console.error("❌ Error loading org chart:", err);
        toast.error("Failed to load org chart");
      });
  }, []);
  return (
    <div className="orgchart-container">
      {/* Zoom Controls */}
      <div className="orgchart-toolbar">
        <button onClick={zoomIn}>➕ Zoom In</button>
        <button onClick={zoomOut}>➖ Zoom Out</button>
        <button onClick={resetZoom}>🔄 Reset Zoom</button>
      </div>
  
      {/* Chart Container */}
      <div className="orgchart-body">
        {orgData ? (
          <OrgChart
            ref={chartRef}
            datasource={orgData}
            chartClass="my-orgchart"
            NodeTemplate={CustomNode}
            pan
            zoom
            onRender={(instance) => setChartInstance(instance)}
          />
        ) : (
          <div>Loading org chart...</div>
        )}
      </div>
    </div>
  );
  
};

export default OrgChartView;
