import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
    Table,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Card,
    CardBody,
    CardHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Label,
    Form,
    FormGroup,
} from 'reactstrap';
import { ArrowLeft, Edit, Trash, PlusCircle, Eye, Upload } from "react-feather";
import { getUserOptions } from 'Services/user.service';
import { activitiesService } from 'Services';
import { useSelector } from "react-redux";
import { fetchfinancialTransaction, createfinancialTransaction, updatefinancialTransaction, deletefinancialTransaction } from 'Services/financialTransactions.service';
import classnames from 'classnames';

const FinancialTransaction = () => {
    const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || '1');
    const [userOptions, setUserOptions] = useState([]); // Store user options
    const [financialTransactionModal, setfinancialTransactionModal] = useState(false);
    const [financialTransactionSearch, setfinancialTransactionSearch] = useState('');
    const [financialTransaction, setfinancialTransaction] = useState([]);
    const [financialTransactionformvalues, setfinancialTransactionformvalues] = useState([
        {
            userId: '',
            transType: '',
            transName: '',
            amount: '',
            effOn: '',
            notes: '',
            currency: 'AED',
        },
    ]);
    const [isfinancialTransactionEditMode, setIsfinancialTransactionEditMode] = useState(false);
    
  const userPermissions = useSelector(
    (state) => (state.login && state.login.profile) || {}
);




    useEffect(() => {
        // Fetch users from API when component mounts
        const fetchUsers = async () => {
            try {
                const response = await getUserOptions(); // Replace with actual service call
                if (response && response.data && Array.isArray(response.data.data)) {
                    const formattedOptions = response.data.data.map(user => ({
                        value: user.id,
                        label: `${user.firstName} ${user.lastName}`
                    }));
                    setUserOptions(formattedOptions);
                } else {
                    console.error("Invalid response format:", response);
                }
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };
        fetchUsers();
    }, []);

    // Ensure tab is set correctly on first load
    useEffect(() => {
        if (!localStorage.getItem('activeTab')) {
            localStorage.setItem('activeTab', '1');
        }
    }, []);

    // Load financial request data when activeTab is '1'
    useEffect(() => {
        if (activeTab === '1') {
            loadfinancialTransactionData();
        }
    }, [activeTab]);

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            localStorage.setItem('activeTab', tab);
        }
    };

    const loadfinancialTransactionData = async () => {
        try {
            const fetchedfinancialTransaction = await fetchfinancialTransaction();
            // Handle the response based on expected structure (direct array)
            if (fetchedfinancialTransaction && Array.isArray(fetchedfinancialTransaction)) {
                setfinancialTransaction(fetchedfinancialTransaction);
            } else {
                console.error('Expected an array, but got:', fetchedfinancialTransaction);
            }
        } catch (error) {
            console.error('Error fetching and filtering financialTransaction:', error);
        }
    };

    const handleUserChange = (e, index) => {
        const selectedUser = userOptions.find(user => user.label === e.target.value);
        if (selectedUser) {
            setfinancialTransactionformvalues((prevValues) => {
                const updatedValues = [...prevValues];
                updatedValues[index] = {
                    ...updatedValues[index],
                    userId: selectedUser.value, // Auto-fill userId
                    userName: selectedUser.label
                };
                return updatedValues;
            });
        }
    };


    const togglefinancialTransactionModal = () => setfinancialTransactionModal(!financialTransactionModal);

    const handlefinancialTransactionFormChange = (e, index) => {
        const { name, value } = e.target;
        setfinancialTransactionformvalues((prevValues) => {
            const updatedValues = [...prevValues];
            updatedValues[index] = { ...updatedValues[index], [name]: value };
            return updatedValues;
        });
    };

    const initializeCreatefinancialTransactionForm = () => {
        setIsfinancialTransactionEditMode(false);
        setfinancialTransactionformvalues([
            {
                userId: '',
                transType: 'Income',
                transName: '',
                amount: '',
                effOn: '',
                notes: '',
                currency: 'AED',
            },
        ]);
        togglefinancialTransactionModal();
    };

    const initializeUpdatefinancialTransactionForm = (financialTransaction) => {
        setfinancialTransactionformvalues([financialTransaction]);
        setIsfinancialTransactionEditMode(true);
        togglefinancialTransactionModal();
    };

    const deletefinancialTransaction = async (id) => {
        try {
            await deletefinancialTransaction(id);
            toast.success('Request deleted successfully!');
            setfinancialTransaction((prevDetails) => prevDetails.filter((financialTransaction) => financialTransaction.id !== id));
        } catch (error) {
            console.error('Error deleting Request:', error);
            toast.error('Failed to delete financialTransaction. Please try again.');
        }
    };

    const handleSavefinancialTransaction = async (e) => {
        e.preventDefault();
        try {
            if (isfinancialTransactionEditMode) {
                await updatefinancialTransaction(financialTransactionformvalues[0].id, financialTransactionformvalues[0]);
                toast.success('Request updated successfully!');
            } else {
                await createfinancialTransaction(financialTransactionformvalues[0]);
                toast.success('Request added successfully!');
            }
            const logData = {
                type: `Financial Transaction Added or Updated`,
                userId: userPermissions.id,
                createdAt: Date.now(),
                customerId: financialTransactionformvalues.userId,
                note: financialTransactionformvalues,
                level: 'YELLO',

            };

            activitiesService.postActivity(logData);

            await loadfinancialTransactionData();
            togglefinancialTransactionModal();
        } catch (error) {
            console.error('Error saving Request:', error);
            toast.error('Failed to save financialTransaction. Please try again.');
        }
    };

    const handleSearchfinancialTransactionChange = (e) => {
        setfinancialTransactionSearch(e.target.value);
    };

    const renderfinancialTransactionModal = () => {
        return (
            <Modal isOpen={financialTransactionModal} toggle={togglefinancialTransactionModal}>
                <ModalHeader toggle={togglefinancialTransactionModal}>
                    {isfinancialTransactionEditMode ? 'Edit Financial Request Info' : 'Add Financial Request Info'}
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSavefinancialTransaction}>
                        {financialTransactionformvalues.map((financialTransaction, id) => (
                            <div key={id}>

                                <FormGroup>
                                    <Label for={`userName_${id}`}>User Name</Label>
                                    <Input
                                        type="select"
                                        name="userName"
                                        id={`userName_${id}`}
                                        value={userOptions.find(user => user.value === financialTransaction.userId)?.label || ""}
                                        onChange={(e) => handleUserChange(e, id)}
                                        required
                                    >
                                        <option value="">Select User</option>
                                        {userOptions.map((user) => (
                                            <option key={user.value} value={user.label}>{user.label}</option>
                                        ))}
                                    </Input>
                                </FormGroup>

                                <FormGroup>
                                    <Label for={`userId_${id}`}>User ID</Label>
                                    <Input
                                        type="text"
                                        name="userId"
                                        id={`userId_${id}`}
                                        value={financialTransaction.userId}
                                        readOnly
                                    />
                                </FormGroup>


                                <Input type="hidden" name="transType" value="Income" />
                                <FormGroup>
                                    <Label for={`transName_${id}`}>Transaction Name</Label>
                                    <Input
                                        type="select"
                                        name="transName"
                                        id={`transName_${id}`}
                                        value={financialTransaction.transName || ''}
                                        onChange={(e) => handlefinancialTransactionFormChange(e, id)}
                                        required
                                    >
                                        <option value="">Select Transaction</option>
                                        <option value="Reimbursements">Reimbursements</option>
                                        <option value="Advance salary - Income">Advance salary - Income</option>
                                        <option value="Leave Encashment">Leave Encashment</option>
                                        <option value="Air Ticket Encashment">Air Ticket Encashment</option>
                                        <option value="Expo Expenses">Expo Expenses</option>
                                        <option value="Overtime">Overtime</option>
                                    </Input>

                                </FormGroup>
                                <FormGroup>
                                    <Label for={`amount_${id}`}>Amount</Label>
                                    <Input
                                        type="number"
                                        name="amount"
                                        id={`amount_${id}`}
                                        value={financialTransaction.amount || ''}
                                        onChange={(e) => handlefinancialTransactionFormChange(e, id)}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for={`effOn_${id}`}>Effective On</Label>
                                    <Input
                                        type="date"
                                        name="effOn"
                                        id={`effOn_${id}`}
                                        value={financialTransaction.effOn || ''}
                                        onChange={(e) => handlefinancialTransactionFormChange(e, id)}
                                        required
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for={`currency_${id}`}>Currency</Label>
                                    <Input
                                        type="text"
                                        name="currency"
                                        id={`currency_${id}`}
                                        value={financialTransaction.currency || 'AED'}
                                        readOnly
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for={`notes_${id}`}>Notes</Label>
                                    <Input
                                        type="textarea"
                                        name="notes"
                                        id={`notes_${id}`}
                                        value={financialTransaction.notes || ''}
                                        onChange={(e) => handlefinancialTransactionFormChange(e, id)}
                                    />
                                </FormGroup>
                            </div>
                        ))}
                        <ModalFooter>
                            <Button color="primary" type="submit">
                                {isfinancialTransactionEditMode ? 'OK' : 'Save'}
                            </Button>
                            <Button color="secondary" onClick={togglefinancialTransactionModal}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Form>
                </ModalBody>
            </Modal>
        );
    };

    return (
        <>
            <Nav tabs>
                <NavItem>
                    <style>
                        {`
    .form-group.w-75 {
      display: none;
 
    }
      .form-inline.search-fulls.col-7 {
        border: none;
      }

    .MuiBox-root.css-19midj6 {
            padding: 3px;
      }
  `}
                    </style>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => toggleTab('1')}
                        style={{ cursor: 'pointer', color: activeTab === '1' ? '#194995' : 'black' }}
                    >
                        Financial Transactions
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Card>
                        <CardHeader>
                            <h3 style={{ fontSize: '24px', fontWeight: 'bold', color: '#194995', marginBottom: '20px' }}>
                                Financial Transactions
                            </h3>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Input
                                    type="text"
                                    placeholder="Search Financial Transactions"
                                    value={financialTransactionSearch}
                                    onChange={handleSearchfinancialTransactionChange}
                                    style={{ width: '300px', marginBottom: '20px' }}
                                />

                                <PlusCircle size={18} onClick={initializeCreatefinancialTransactionForm} style={{ cursor: 'pointer' }} />

                            </div>
                        </CardHeader>
                        <CardBody>
                            <Table bordered hover responsive>
                                <thead style={{ backgroundColor: '#E5E5E5', fontSize: '15px' }}>
                                    <tr>
                                        <th>User ID</th>
                                        <th>Transaction Name</th>
                                        <th>Transaction Type</th>
                                        <th>Amount</th>
                                        <th>Effective On</th>
                                        <th>Notes</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {financialTransaction
                                        .filter((item) =>
                                            Object.values(item).some((val) =>
                                                String(val).toLowerCase().includes(financialTransactionSearch.toLowerCase())
                                            )
                                        )
                                        .map((financialTransaction) => (
                                            <tr key={financialTransaction.id}>
                                                <td>{financialTransaction.userId}</td>
                                                <td>{financialTransaction.transName}</td>
                                                <td>{financialTransaction.transType}</td>
                                                <td>{financialTransaction.amount}</td>
                                                <td>{financialTransaction.effOn}</td>

                                                <td>{financialTransaction.notes}</td>
                                                <td>

                                                    <Edit size={18} onClick={() => initializeUpdatefinancialTransactionForm(financialTransaction)} style={{ cursor: 'pointer' }} />

                                                    <Trash size={18} onClick={() => deletefinancialTransaction(financialTransaction.id)} style={{ cursor: 'pointer', color: 'red' }} />

                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </TabPane>
            </TabContent>
            {renderfinancialTransactionModal()}
        </>
    );
};

export default FinancialTransaction;
