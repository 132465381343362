import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import {
    Table,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Card,
    CardBody,
    CardHeader,
    Form,
    FormGroup,
    Label,
} from 'reactstrap';
import { fetchfinancialTransaction } from 'Services/financialTransactions.service';
import { fetchemployeeS, salarySlip } from 'Services/employeeS.service';
import { UsersPersonalDetail } from 'Services/usersPersonalDetail.service';
import { VacationDays } from 'Services/vacationdays.service';
import { getUserOptions } from 'Services/user.service';
import { Download } from 'react-feather';
const SalaryCalculation = () => {
    const [employees, setEmployees] = useState([]);
    const [vacationDays, setVacationDays] = useState([]);
    const [financialTransactions, setFinancialTransactions] = useState([]);
    const [userDetails, setUserDetails] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState("");
    const [selectedMonth, setSelectedMonth] = useState(new Date());
    const [users, setUsers] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const employeeData = await fetchemployeeS();
            const vacationData = await VacationDays();
            const transactionData = await fetchfinancialTransaction();
            const userDetailData = await UsersPersonalDetail();
            const userOptionsResponse = await getUserOptions();

            setEmployees(Array.isArray(employeeData.rows) ? employeeData.rows : []);
            setVacationDays(Array.isArray(vacationData) ? vacationData : []);
            setFinancialTransactions(Array.isArray(transactionData) ? transactionData : []);
            setUserDetails(Array.isArray(userDetailData) ? userDetailData : []);

            if (userOptionsResponse && userOptionsResponse.data && Array.isArray(userOptionsResponse.data.data)) {
                const formattedOptions = userOptionsResponse.data.data.map(user => ({
                    value: user.id,
                    label: `${user.firstName} ${user.lastName}`
                }));
                setUsers(formattedOptions);
            } else {
                setUsers([]);
            }
        } catch (error) {
            toast.error("Error fetching data");
        }
    };

    const getSelectedMonthStr = () => {
        if (!(selectedMonth instanceof Date) || isNaN(selectedMonth)) {
            return "";
        }
        return selectedMonth.toISOString().slice(0, 7);
    };

    const calculateSalaryDetails = (userId) => {
        const employee = employees.find(emp => emp.empNo == userId);
        if (!employee) return null;

        let totalSalary = Number(employee.totalS) || 0;
        let dailySalary = totalSalary / 30.4167;
        let noOfUnpaidVacationDays = 0;
        let amountDeducted = 0;
        let amountAdded = 0;
        const selectedMonthStr = getSelectedMonthStr();

        vacationDays.filter(vac => vac.empNo == userId && vac.transactionType === 'UNPAID VACATION' &&
            new Date(vac.fromDate).toISOString().slice(0, 7) === selectedMonthStr)
            .forEach(vac => {
                noOfUnpaidVacationDays += Number(vac.noOfDays);
                amountDeducted += dailySalary * Number(vac.noOfDays);
            });

        financialTransactions.filter(trx => trx.userId == userId &&
            new Date(trx.effOn).toISOString().slice(0, 7) === selectedMonthStr)
            .forEach(trx => {
                let transactionAmount = Number(trx.amount);
                if (trx.transName === 'BONUS' || trx.transName === 'Reimbursements' || trx.transName === 'Leave Encashment' || trx.transName === 'Air Ticket Encashment' || trx.transName === 'Expo Expenses' || trx.transName === 'Overtime') {
                    amountAdded += transactionAmount;
                }
                if (trx.transName === 'DEDUCTION' || trx.transName === 'Advance salary - Income') {
                    amountDeducted += transactionAmount;
                }
            });

        let finalSalary = totalSalary - amountDeducted + amountAdded;
        return { totalSalary, noOfUnpaidVacationDays, amountDeducted, amountAdded, finalSalary };
    };

    const branchEmployees = userDetails.filter(user => user.branch === selectedBranch)
        .map(user => {
            const salaryDetails = calculateSalaryDetails(user.userid);
            return salaryDetails ? { ...user, ...salaryDetails } : null;
        }).filter(user => user !== null);

    const handleSalarySlipDownload = async (empNo, nameLabel = '') => {
        if (!selectedMonth) {
            toast.error("Please select a month!");
            return;
        }

        const formattedMonth = selectedMonth.toISOString().slice(0, 7);

        try {
            const response = await salarySlip({
                userid: empNo,
                salaryMonth: formattedMonth,
            });

            if (response?.error) {
                toast.error(response.error);
                return;
            }

            // Uncomment if you're receiving a PDF blob directly:
            // const pdfBlob = new Blob([response.data], { type: "application/pdf" });
            // const pdfUrl = URL.createObjectURL(pdfBlob);
            // const link = document.createElement("a");
            // link.href = pdfUrl;
            // link.download = `SalarySlip_${empNo}_${formattedMonth}.pdf`;
            // document.body.appendChild(link);
            // link.click();
            // document.body.removeChild(link);

            toast.success(`Salary slip generated for ${nameLabel || empNo}!`);
        } catch (error) {
            console.error("Error generating salary slip:", error);
            toast.error("Failed to generate salary slip.");
        }
    };


    return (

        <Card>
            <CardHeader>
                <h3>Salary Calculation</h3>
            </CardHeader>
            <style>
                {`
    .form-group.w-75 {
      display: none;
    }
    .form-inline.search-fulls.col-7 {
        border: none;
    }
    .MuiBox-root.css-19midj6 {
        padding: 3px;
    }
    `}
            </style>

            <CardBody>

                <Form>
                    <FormGroup>
                        <Label>Select Month</Label>
                        <DatePicker
                            selected={selectedMonth}
                            onChange={date => setSelectedMonth(date)}
                            dateFormat="yyyy-MM"
                            showMonthYearPicker
                            className="form-control"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Select Employee</Label>
                        <Input type="select" onChange={(e) => setSelectedUser(e.target.value)}>
                            <option value="">Select User</option>
                            {users.map(user => (
                                <option key={user.value} value={user.value}>{user.label}</option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Select Branch</Label>
                        <Input type="select" onChange={(e) => setSelectedBranch(e.target.value)}>
                            <option value="">Select Branch</option>
                            <option value="GoDo LTD. Dubai Branch">GODO LTD. Dubai Branch</option>
                            <option value="GODO Introduction Financial Services LLC">GODO Introduction Financial Services LLC</option>
                        </Input>
                    </FormGroup>
                </Form>

                {selectedUser && (() => {
                    const salaryDetails = calculateSalaryDetails(selectedUser);
                    return salaryDetails ? (
                        <Table bordered>
                            <thead>
                                <tr>
                                    <th>Employee</th>
                                    <th>Total Salary (Before Calculations)</th>
                                    <th>No of Unpaid Vacation Days</th>
                                    <th>Amount Deducted</th>
                                    <th>Amount Added</th>
                                    <th>Total Salary (After Calculations)</th>
                                    <th>Salary Slip</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{users.find(user => user.value === selectedUser)?.label || selectedUser}</td>
                                    <td>{salaryDetails.totalSalary}</td>
                                    <td>{salaryDetails.noOfUnpaidVacationDays}</td>
                                    <td>{salaryDetails.amountDeducted}</td>
                                    <td>{salaryDetails.amountAdded}</td>
                                    <td>{salaryDetails.finalSalary}</td>
                                    <td>
                                        <Download
                                            size={18}
                                            style={{ cursor: "pointer", color: "blue" }}
                                            onClick={() => handleSalarySlipDownload(
                                                selectedUser,
                                                users.find(user => user.value === selectedUser)?.label
                                            )}
                                        />
                                    </td>

                                </tr>
                            </tbody>
                        </Table>
                    ) : null;
                })()}
                {selectedBranch && (
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>Employee</th>
                                <th>Total Salary (Before Calculations)</th>
                                <th>No of Unpaid Vacation Days</th>
                                <th>Amount Deducted</th>
                                <th>Amount Added</th>
                                <th>Total Salary (After Calculations)</th>
                                <th>Salary Slip</th>

                            </tr>
                        </thead>
                        <tbody>
                            {branchEmployees.map(user => (
                                <tr key={user.userid}>
                                    <td>{user.name}</td>
                                    <td>{user.totalSalary}</td>
                                    <td>{user.noOfUnpaidVacationDays}</td>
                                    <td>{user.amountDeducted}</td>
                                    <td>{user.amountAdded}</td>
                                    <td>{user.finalSalary}</td>
                                    <td>
                                        <Download
                                            size={18}
                                            style={{ cursor: "pointer", color: "blue" }}
                                            onClick={() => handleSalarySlipDownload(user.userid, user.name)}
                                        />
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </CardBody>
        </Card>
    );
};

export default SalaryCalculation;
