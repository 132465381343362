import api from './api';

export const UsersPersonalDetail = () => {
    return api.get('/v1/crm/UsersPersonalDetail');
};

export const createUsersPersonalDetail = (data) => {
    return api.post('/v1/crm/UsersPersonalDetail', data);
};

export const updateUsersPersonalDetail = (id, data) => {
  console.log('updateUsersFamilyDetail...',data);
    return api.put(`/v1/crm/UsersPersonalDetail/${id}`, data);
   
};

export const deleteUsersPersonalDetail = (id) => {
    return api.delete(`/v1/crm/UsersPersonalDetail/${id}`);
};

// export const addImage = (id,data) => {
//     console.log(data, 'file')
//     return api.put(`/v1/crm/UsersPersonalDetail/image/${id}`,data);
// };

export const addImage = async (id,formData) => {
    console.log("formdata.................: ", formData);
    try {
      const response = await api.put(`/v1/crm/UsersPersonalDetail/addDocs/${id}`, formData,{
        headers: {
          'Content-Type': 'multipart/form-data',
          
        },
      });
      
      console.log('data: ', response);
      return response; // Return the created document data
    } catch (error) {
      console.error('Error creating document:', error.response.data || error.message);
      throw error;
    }
  };

  export const getDocumentSas = (id)=> {
    //return api.get('/v1/crm/documents/'+id, '?fileNumber='+number)
    return api.get(`/v1/crm/UsersPersonalDetail/${id}`)
  }

 
  // services/usersPersonalDetail.service.js
export const updateUsersPersonalDetailStatus = (id, status) => {
  return api.put(`/v1/crm/usersPersonalDetail/${id}/status`, { status });
};


export const sendEmailHandler = (id, data) => {
  console.log('sendEmail...',data);
    return api.post(`/v1/crm/UsersPersonalDetail/sendEmail/${id}`, data);
};    
   
// Service
export const getActiveUsers = () => {
  return api.get(`/v1/crm/UsersPersonalDetail?status=Active`);
};
