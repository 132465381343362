import React, { useEffect, useState } from 'react';
import { leadService } from 'Services';
import { useSelector } from "react-redux";

import bellIcon from '../../../../assets/images/bellIcon.svg';

import './LeadPopup.css';

const LeadPopup = () => {
  const [hide, setHide] = useState(true);
  const [warmlead, setWarmLead] = useState([]);
  const [hotlead, setHotLead] = useState([]);
  const [newLeads, setNewLeads] = useState([]);
  const [reOpen, setReOpen] = useState([]);

  const permissions = useSelector(
    (state) => (state.login && state.login.profile) || {}
  );

  const checkStatusChanges = () => {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith('lead-') && key.endsWith('-status-change')) {
        const { status, date } = JSON.parse(localStorage.getItem(key));

        if (status === 'Warm-callafter10days' || status === 'Hot-Interested') {
          const statusChangeDate = new Date(date);
          const currentDate = new Date();
          setHide(true);
          // Calculate the difference in hours
          const timeDiff = Math.abs(currentDate - statusChangeDate);
          const hoursDiff = Math.ceil(timeDiff / (1000 * 60 * 60)); // Difference in hours

          // Check if popup should be shown
          // const lastPopupDate = localStorage.getItem(`popup-${key}`);
          // const showPopup = !lastPopupDate || hoursDiff >= 6;

          // if (showPopup) {
          //   console.log('Showing popup');
          //   setHide(false);
          //   localStorage.setItem(`popup-${key}`, currentDate.toISOString());
          // }
        }
      }
    });
  };

  useEffect(() => {
    // Call the check function immediately upon login
    checkStatusChanges();
    // Set up an interval to check every 2 minutes
    const intervalId = setInterval(() => {
      checkStatusChanges();
    }, 1000 * 60 * 60 * 6);

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array


  useEffect(() => {
    if (permissions.id) {
      leadService.getUserLeads({ agentId: `${permissions.id && permissions.id}` })
        .then((res) => {
          // Filter and set the leads
          setHotLead(res.data && res.data.filter(({ callStatus }) => callStatus && callStatus.replace(/\s+/g, '') === 'Hot-Interested'));
          setWarmLead(res.data && res.data.filter(({ callStatus }) => callStatus && callStatus.replace(/\s+/g, '') === 'Warm-callafter10days'));
          setNewLeads(res.data && res.data.filter(({ callStatus }) => callStatus && callStatus.replace(/\s+/g, '') === 'NewLead'));
          setReOpen(res.data && res.data.filter(({ callStatus }) => callStatus && callStatus.replace(/\s+/g, '') === 'ReOpen'));

        })
        .catch((err) => console.log(err));
    }
  }, [permissions]);

  return (
    <div className={` ${hide ? 'd-flex' : 'd-none'} position-fixed bgBackgroundColor top-0 w-100 h-100 align-items-center justify-content-center`}>
      <div className='leadsCard position-relative d-flex flex-column align-items-center justify-content-around'>
        <img src={bellIcon} alt="bellIcon" className='position-fixed topScroll' />
        <div className='d-flex align-items-end justify-content-end w-100 mr-4 mt-2 fs-4 cursor-pointer' onClick={() => setHide(false)}>X </div>
        <h1 className='fs-3 py-4'>Leads Notifications</h1>
        <div className='d-flex align-items-center justify-content-around w-100'>
          <div>
            <span className='totalLeads'>New Leads</span>
            <p className='text-center fs-2 mt-3'>{newLeads && newLeads.length}</p>
          </div>
          <div className='borderStyle'></div>
          <div>
            <span className='warmLeads'>Warm Leads</span>
            <p className='text-center fs-2 mt-3'>{warmlead && warmlead.length}</p>
          </div>
          <div className='borderStyle'></div>
          <div>
            <span className='hotLeads'>Hot Leads</span>
            <p className='text-center fs-2 mt-3'>{hotlead && hotlead.length}</p>
          </div>
          <div className='borderStyle'></div>
          <div>
            <span className='hotLeads'>ReOpen Leads</span>
            <p className='text-center fs-2 mt-3'>{reOpen && reOpen.length}</p>
          </div>
        </div>
      </div>
    </div>
  )
};

export default LeadPopup;
